<template>
    <v-dialog v-model="rfInquiryDialog" persistent max-width="98%" scrollable>
        <v-card class="pb-1">
            <v-container fluid style="background-color: white; overflow-y: scroll">
                <v-col>
                    <v-card-text>
                        <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <span class="headline">Repair Form Details</span>
                                </v-card-title>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn
                                    text
                                    icon
                                    color="gray"
                                    class="float-right"
                                    @click="closeRfInquiryDialog()"
                                >
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-0" />

                        <div class="px-3 pb-4" style="width:100%">
                            <v-layout row class="px-4 py-2 mb-2">
                                <v-flex lg6 style="font-weight:bold">
                                    Repair Form Information
                                </v-flex>
                                <v-flex lg6>
                                    <v-layout row class="mr-0">
                                        <v-spacer />
                                        <v-flex v-if="!!rfEstId" shrink class="pr-2 pt-2">
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="showEstHistoryDialog()"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                        >
                                                        <v-icon color="#f69f1a">mdi-message-text</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Conversation</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <!-- <v-btn @click="showOverride()">Print QrCode </v-btn> -->
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="showOverride()"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                    >
                                                        <v-icon color="#f69f1a">mdi-qrcode</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Print QrCode</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <!-- <v-btn @click="showExportDialog()">Export</v-btn> -->
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="showExportDialog()"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                    >
                                                        <v-icon color="#f69f1a">mdi-printer-pos-wrench</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Export</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex style="text-align:end" shrink class="pr-2 pt-2">
                                            <!-- <v-btn @click="override.override_dialog = true">Re-print </v-btn> -->
                                            <v-tooltip bottom color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        @click="override.override_dialog = true"
                                                        :loading="loading"
                                                        color="#f69f1a"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        small
                                                        icon
                                                        text
                                                    >
                                                        <v-icon color="#f69f1a">mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Re-print RF</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex shrink class="pt-2">
                                            <div style="text-align:end">
                                                <ServiceFilesViewer v-if="!!rfId" :rfId="rfId" :rfNumber="rfNumber" :buttonColor="`#f69f1a`"/>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>


                            <v-card class="px-8 py-2 mb-2" :loading="loading">
                                <v-layout row class="pa-2">
                                    <v-flex lg3>
                                        <div>
                                            RF#:<span style="color:blue">{{ rfNumber }}</span>
                                        </div>
                                        <div>
                                            RD#:<span style="color:blue">{{ rrNumber }}</span>
                                        </div>
                                        <div>
                                            Pullout#:<span style="color:blue">{{ pulloutNumber }}</span>
                                        </div>
                                        <div>
                                            Issued Date:<span style="color:blue">{{ issuedDate }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex lg3>
                                        <div>
                                            Status: <span style="color:blue">{{ rfStatus }}</span>
                                        </div>
                                        <div>
                                            Assigned Technician: <span style="color:blue">{{ assignedTechnician }}</span>
                                        </div>
                                        <div v-if="!!reasonForRequote">
                                            Reason for Requote: <span style="color:blue">{{ reasonForRequote }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex lg3>
                                        <div>
                                            Crate Number: <span style="color:blue">{{ crateNumber }}</span>
                                        </div>
                                        <div>
                                            Location Number: <span style="color:blue">{{ locationNumber }}</span>
                                        </div>
                                        <div v-if="!!itemRepairStatus">
                                            Repair Status:<span style="color:blue">{{ itemRepairStatus }}</span>
                                        </div>
                                        <div v-if="!!itemRepairStartedDate">
                                            Repair Start Date:<span style="color:blue">{{ itemRepairStartedDate }}</span>
                                        </div>
                                        <div v-if="!!itemRepairCompletedDate">
                                            Repair Completed Date:<span style="color:blue">{{ itemRepairCompletedDate }}</span>
                                        </div>
                                        <div v-if="!!itemRepairCancelledDate">
                                            Repair Cancelled Date:<span style="color:blue">{{ itemRepairCancelledDate }}</span>
                                        </div>
                                        <div v-if="!!itemQaApprovedBy">
                                            Approved QA/QC By:<span style="color:blue">{{ itemQaApprovedBy }}</span>
                                        </div>
                                        <div v-if="!!itemQaRejectedBy">
                                            Reject QA/QC By:<span style="color:blue">{{ itemQaRejectedBy }}</span>
                                        </div>
                                        <div v-if="!!itemQaRejectedBy">
                                            QA/QC Remarks:<span style="color:blue">{{ itemQaRemarks }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex v-if="!!approvedForReplacement" lg3>
                                        <div>
                                            Approved For Replacement By:<span style="color:blue">{{ approvedForReplacementBy }}</span>
                                        </div>
                                        <div>
                                            Approved For Replacement Date:<span style="color:blue">{{ approvedForReplacementDate }}</span>
                                        </div>
                                    </v-flex>

                                    <v-flex v-if="!!approvedForCm" lg3>
                                        <div>
                                            CM Number: <span style="color:blue">{{ cmNumber }}</span>
                                        </div>
                                        <div>
                                            Approved Cm By: <span style="color:blue">{{ approvedCmBy }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div class="pa-2" style="font-weight:bold">
                                Document Information
                            </div>

                            <v-card class="px-4 py-2 mb-2" :loading="loading">
                                <v-layout class="pa-2">
                                    <v-flex lg3>
                                        <div>
                                            SQ#:<span style="color:blue">{{ sqNumber }}</span>
                                        </div>
                                        <div>
                                            SO#:<span style="color:blue">{{ soNumber }}</span>
                                        </div>
                                        <div>
                                            PL#:<span style="color:blue">{{ plNumber }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div class="pa-2" style="font-weight:bold">
                                Delivery Information
                            </div>

                            <v-card class="px-4 py-2 mb-2" :loading="loading">
                                <v-layout class="pa-2">
                                    <v-flex lg3>
                                        <div>
                                            DR#:<span style="color:blue">{{ rfDeliveryNumber }}</span>
                                        </div>
                                        <div>
                                            Delivered Date:<span style="color:blue">{{ rfDeliveredDate }}</span>
                                        </div>
                                        <div>
                                            Collected Date:<span style="color:blue">{{ rfCollectedDate }}</span>
                                        </div>
                                        <div>
                                            Processed By:<span style="color:blue">{{ rfDeliveryProcessedBy }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div class="pa-2" style="font-weight:bold">
                                Customer Information
                            </div>

                            <v-card class="px-8 py-2 mb-2" :loading="loading">
                                <v-layout row class="pa-2">
                                    <v-flex lg6>
                                        <div>
                                            Company Name:<span style="color:blue">{{ companyName }}</span>
                                        </div>
                                        <div>
                                            Customer Name:<span style="color:blue">{{ customerName }}</span>
                                        </div>
                                        <div>
                                            Contact Number:<span style="color:blue">{{ customerContactNumber }}</span>
                                        </div>
                                        <div>
                                            Address:<span style="color:blue">{{ customerAddress }}</span>
                                        </div>
                                        <div>
                                            Email:<span style="color:blue">{{ customerEmal }}</span>
                                        </div>
                                    </v-flex>
                                    <v-flex lg6>

                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div class="pa-2" style="font-weight:bold">
                                Item Information
                            </div>

                            <v-card class="px-8 py-2 mb-2" :loading="loading">
                                <v-layout row class="pa-2">
                                    <v-flex lg12>
                                        <div>
                                            Warranty Type:<span style="color:blue">{{ itemWarrantyType }}</span>
                                        </div>
                                        <div>
                                            Purchase Date:<span style="color:blue">{{ itemPurchaseDate }}</span>
                                        </div>
                                        <div>
                                            Item Model:<span style="color:blue">{{ itemModel }}</span>
                                        </div>
                                        <div>
                                            Item Name:<span style="color:blue">{{ itemName }}</span>
                                        </div>
                                        <div>
                                            Category:<span style="color:blue">{{ itemCategory }}</span>
                                        </div>
                                        <div>
                                            Serial No:<span style="color:blue">{{ itemSerial }}</span>
                                        </div>
                                        <div>
                                            Accessories:<span style="color:blue">{{ itemAccessories }}</span>
                                        </div>
                                        <div>
                                            Other Accessory:<span style="color:blue">{{ itemOtherAccessory }}</span>
                                        </div>
                                        <div>
                                            Unit Condition:<span style="color:blue">{{ itemUnitCondition }}</span>
                                        </div>
                                        <div>
                                            Other Unit Condition:<span style="color:blue">{{ itemOtherUnitCondition }}</span>
                                        </div>
                                        <div>
                                            Complaint / Symptom:<span style="color:blue">{{ itemDefectDescription }}</span>
                                        </div>
                                        <div>
                                            Remarks:<span style="color:blue">{{ itemDefectRemarks }}</span>
                                        </div>
                                        <div>
                                            Symptom As Tested:<span style="color:blue">{{ itemSymptomUponTested }}</span>
                                        </div>
                                        <div>
                                            Job Done:<span style="color:blue">{{ itemJobDone }}</span>
                                        </div>
                                        <div>
                                            Change Warranty Reason:<span style="color:blue">{{ itemWarrantyRejectReason }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>

                            <div class="pa-2" style="font-weight:bold">
                                Sales Quotation Information
                            </div>
                            <!-- <v-card class="px-8 py-2 mb-2"> -->
                            <v-expansion-panels focusable>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-data-table
                                            :headers="quotationHeaders"
                                            :items="currentSalesQuotation"
                                            :hide-default-footer="true"
                                            :loading="loading"
                                        >
                                            <template v-slot:item.created_at="{ item }">
                                                {{ date(item.created_at) }}
                                            </template>

                                            <template v-slot:item.total_amount="{ item }">
                                                {{ item.total_amount | currency }}
                                            </template>

                                            <template v-slot:item.action="{ item }">
                                                    <span class="text-nonwrap">
                                                        <v-btn text icon color="orange">
                                                            <v-icon class="btn-action" small @click="showQuotationItemsDialog(item)">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </span>
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row class="d-flex justify-center my-4" style="font-weight:bold">
                                            <div>
                                                Old Sales Quotations
                                            </div>
                                        </v-row>
                                        <v-data-table
                                            :headers="quotationHeaders"
                                            :items="oldSalesQuotations"
                                            :items-per-page="oldSalesQuotations.length"
                                            :loading="loading"
                                        >
                                            <template v-slot:item.created_at="{ item }">
                                                {{ date(item.created_at) }}
                                            </template>

                                            <template v-slot:item.total_amount="{ item }">
                                                {{ item.total_amount | currency }}
                                            </template>

                                            <template v-slot:item.action="{ item }">
                                                    <span class="text-nonwrap">
                                                        <v-btn text icon color="orange">
                                                            <v-icon class="btn-action" small @click="showQuotationItemsDialog(item)">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </span>
                                            </template>
                                        </v-data-table>

                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <div class="pa-2" style="font-weight:bold">
                                Repair Form Movement
                            </div>

                            <v-expansion-panels focusable>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-data-table
                                            :headers="rfMovementheaders"
                                            :items="lastMovement"
                                            :items-per-page="lastMovement.length"
                                            :hide-default-footer="true"
                                            :loading="loading"
                                        >
                                            <template v-slot:item.updated_by="{ item }">
                                                {{ updatedBy(item) }}
                                            </template>

                                            <template v-slot:item.created_at="{ item }">
                                                {{ date(item.created_at) }}
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-data-table
                                            :headers="rfMovementheaders"
                                            :items="rfMovements"
                                            :items-per-page="rfMovements.length"
                                            :hide-default-footer="true"
                                            :loading="loading"
                                        >
                                            <template v-slot:item.updated_by="{ item }">
                                                {{ updatedBy(item) }}
                                            </template>

                                            <template v-slot:item.created_at="{ item }">
                                                {{ date(item.created_at) }}
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <div class="pa-2" style="font-weight:bold">
                                {{ `${itemRepairStatus == 'For-Replacement' ? 'Replaced' : 'Recommended'} Parts Information` }}
                            </div>

                            <v-card class="px-8 py-2 mb-2">
                                <v-data-table
                                    :headers="partsOrderItemsheaders"
                                    :items="partsOrderItems"
                                    :loading="loading"
                                >
                                <template v-slot:item.created_at="{ item }">
                                    {{ date(item.created_at) }}
                                </template>
                                </v-data-table>
                            </v-card>
                        </div>

                        <!-- Quotation Items Dialog -->
                        <v-dialog v-model="quotationItemsDialog" persistent max-width="98%" scrollable>
                            <v-card style="height:100%">
                                <v-row class="m-0">
                                    <v-col>
                                        <v-card-title>
                                            <span class="headline">Sales Quotation Items</span>
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="pull-right-10 p-2">
                                        <v-btn text icon color="gray" class="float-right" @click="closeQuotationItemsDialog()">
                                            <v-icon>mdi-close-circle</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-divider class="my-0" />
                                <div class="pa-4">
                                    <v-data-table
                                        :headers="quotationItemsHeaders"
                                        :items="salesQuotationItems"
                                    >
                                    <template v-slot:item.foc="{ item }">
                                        {{ !!item.foc ? 'Yes' : 'No' }}
                                    </template>

                                    <template v-slot:item.selling_price="{ item }">
                                        {{ item.selling_price | currency }}
                                    </template>

                                    <template v-slot:item.total="{ item }">
                                        {{ item.total | currency }}
                                    </template>
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-dialog>
                    </v-card-text>
                </v-col>
            </v-container>
        </v-card>

        <ConversationDialog :rfEstId="rfEstId" />

        <v-dialog v-model="exportDialog" persistent max-width="35%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Export</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeExportDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="row mx-0">
                    <div class="col-sm-12 col-md-4 mb-3">
                        <div style="text-align:center">
                            <v-btn @click="printEstimationForm()" color="#f69f1a">Sales Quotation Form</v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <!-- For Estimation Form Print -->
        <v-container fluid style="display:none">
            <div id="sq_logo" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <span style="font-size: 18px; font-weight: bold;">Sales Quotation Form</span>
                        <br>RF#: {{ rfNumber }}, Crate# {{ crateNumber }}, Loc# {{ locationNumber }}
                        <br>Company Name: {{ companyName }}
                        <br>Customer Name: {{ customerName }}
                        <br>Warranty Type: {{ itemWarrantyType }}
                    </p>
                </div>
            </div>
             <div id="sq_headers" >
                <div style="font-size: 12px; line-height: 1.9">
                    <p>
                        <br>Model: {{ itemModel }}
                        <br>Name: {{ itemName }}
                        <br>Serial Number: {{ itemSerial }}
                        <br>Complaint / Symptom: {{ itemDefectDescription }}
                    </p>
                </div>
            </div>

            <!-- <div>
                <table id="est_items">
                    <tbody>
                        <tr>
                            <td>Qty</td>
                            <td>Model</td>
                            <td>Name</td>
                            <td>Category</td>
                            <td>Part Number</td>
                        </tr>
                        <tr v-for="sp in estItems" :key="sp.id">
                            <td>{{ sp.requested_qty || '' }}</td>
                            <td>{{ sp.model || '' }}</td>
                            <td>{{ sp.name || '' }}</td>
                            <td>{{ sp.category || '' }}</td>
                            <td>{{ sp.item.part_num || '' }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">Total: {{ estItems.length }}</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
            <div>
                <table id="sq_items">
                    <tbody>
                        <tr>
                            <td>Qty</td>
                            <td>Model</td>
                            <td>Name</td>
                            <td>Category</td>
                            <td>Selling Price</td>
                            <td>Discounted Price</td>
                        </tr>
                        <tr v-for="sqi in salesQuotationItems" :key="sqi.id">
                            <td>{{ sqi.quantity || '' }}</td>
                            <td>{{ sqi.view_item.model || '' }}</td>
                            <td>{{ sqi.view_item.name || '' }}</td>
                            <td>{{ sqi.view_item.item_category_text || '' }}</td>
                            <td>{{ sqi.srp || '' }}</td>
                            <td>{{ sqi.disc_price || '' }}</td>
                        </tr>
                        <tr>
                            <td colspan="6">Total: {{ salesQuotationItems?.length }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table id="sq_footer">
                    <tbody>
                        <tr>
                            <td>Date:</td>
                            <td>{{ dateToday }}</td>
                            <td>Received By:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Technician:</td>
                            <td>{{ assignedTechnician }}</td>
                            <td>Prepared By:</td>
                            <td>{{ preparedBy }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <ConfirmOverrideComponentVue
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="'Re-Print RF'"
                :module_name="'Inquiry'"
                @closeConfirmOverride="closeConfirmOverride"
            ></ConfirmOverrideComponentVue>

            <ConfirmOverrideComponentVue
                :approve_override_dialog="override.override_dialogs"
                :departments_allowed="override.override_departments_allowed"
                :module_name="'Inquiry'"
                :heading="'Override'"
                @closeConfirmOverride="closeConfirmOverrides"
            ></ConfirmOverrideComponentVue>

            <v-flex lg1 class="mr-2" @click="showQrDialog()" style="cursor:pointer">
                <VueQrcode :value="!!rfNumber ? rfNumber : 'rfNumber'" ref="qr" id="qr" :options="{ width: 120, errorCorrectionLevel: 'H' }"></VueQrcode>
            </v-flex>

            <PrintRepairFormComponentVue></PrintRepairFormComponentVue><!-- if="print_repair_form" :rfid="this.rfId" @printed="printed"-->
        </v-container>

    </v-dialog>
</template>

<script>
import ConversationDialog from '../RepairFormViewComponent/ConversationDialog.vue';
import ServiceFilesViewer from '../../Base/BaseFilesViewer.vue';
import PrintRepairFormComponentVue from '../../PrintRepairFormComponent.vue';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueQrcode from 'qrcode.vue'
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';

export default {
    data() {
        return {
            print_repair_form:false,
            search: '',
            customerName: '',
            customerContactNumber: '',
            customerAddress: '',
            customerEmal: '',
            repairForm: null,
            rfNumber: '',
            assignedTechnician: '',
            issuedDate: '',
            rfStatus: '',
            rrNumber: '',
            pulloutNumber: '',
            itemWarrantyType: '',
            itemPurchaseDate: '',
            itemModel: '',
            itemName: '',
            itemCategory: '',
            itemSerial: '',
            itemAccessories: '',
            itemOtherAccessory: '',
            itemUnitCondition: '',
            itemOtherUnitCondition: '',
            itemDefectDescription: '',
            itemDefectRemarks: '',
            quotations: [],
            quotationItems: [],
            quotationHeaders: [
                { text: 'Quotation No', value: 'quote_num' },
                { text: 'Total Amount', value: 'total_amount' },
                { text: 'Quotation Date', value: 'created_at' },
                { text: 'Action', value: 'action', align: 'center' },
            ],
            quotationItemsHeaders: [
                { text: 'Qty', value: 'quantity' },
                { text: 'Item Model', value: 'view_item.model' },
                { text: 'Item Name', value: 'view_item.name' },
                { text: 'Category', value: 'view_item.item_category_text' },
                { text: 'Selling Price', value: 'srp' },
                { text: 'Discounted Price', value: 'disc_price' },
                { text: 'Total', value: 'total_amount' },
                { text: 'FOC', value: 'foc' },
                // { text: 'Status', value: 'status' },
            ],
            partsOrderItemsheaders: [
                { text: 'Requested Qty', value: 'requested_qty' },
                { text: 'Item Model', value: 'model' },
                { text: 'Item Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Requested Date', value: 'created_at' },
                { text: 'Reason For Return', value: 'return_reason' },
                { text: 'Status', value: 'status' },
            ],
            partsOrderItems: [],
            quotationItemsDialog: false,
            rfEstId: '',
            rfMovementheaders: [
                { text: 'Status', value: 'status' },
                { text: 'Updated By', value: 'updated_by' },
                { text: 'Created At', value: 'created_at' },
            ],
            rfMovements: [],
            rfId: '',
            itemRepairStatus: '',
            itemRepairStartedDate: '',
            itemRepairCompletedDate: '',
            itemRepairCancelledDate: '',
            rfDeliveryNumber: '',
            rfDeliveredDate: '',
            rfCollectedDate: '',
            rfDeliveryProcessedBy: '',
            itemSymptomUponTested: "",
            itemJobDone: "",
            cmNumber: '',
            approvedCmBy: '',
            itemWarrantyRejectReason: '',
            approvedForReplacementBy: '',
            approvedForReplacementDate: '',
            approvedForCm: false,
            approvedForReplacement: false,
            itemQaApprovedBy: '',
            itemQaRejectedBy: '',
            itemQaRemarks: '',

            crateNumber: '',
            locationNumber: '',
            exportDialog: false,

            estItems: [],
            companyName: '',
            dateToday: this.$dayjs().format('YYYY-MM-DD, hh:mm:ss a'),
            preparedBy: '',
            lastMovement: [],

            sqNumber: '',
            soNumber: '',
            plNumber: '',
            override: {
                override_dialog: false,
                override_dialogs: false,
                errors: [],
                // override_departments_allowed: ['SM', 'EX', 'IT', 'ASD'],
                override_departments_allowed: ['ASD'],
                override_authenticated: false,
            },
            printedQrcode: false,
            loading: true,
            salesQuotations: [],
            salesQuotationItems: [],
            currentSalesQuotationItems: [],
            currentSalesQuotation: [],
            oldSalesQuotations: [],
            reasonForRequote: '',
        }
    },
    components: {
        ConversationDialog,
        ServiceFilesViewer,
        PrintRepairFormComponentVue,
        ConfirmOverrideComponentVue,
        VueQrcode,

    },
    props: ['repairFormId'],
    computed: {
        ...mapGetters([
            'GET_SEARCH_REPAIR_FORM',
        ]),
        rfInquiryDialog() {
            this.loading = true;
            return this.$store.state.service.rfInquiryDialog;
        }
    },
    watch: {
        repairFormId() {
            if(!!this.repairFormId) {
                this.getRfDetails();
            }
        },
        repairForm() {
            this.clearFields();
            if(!!this.repairForm) {
                this.loading = false;
                let rf = this.repairForm;
                this.rfId = this.repairForm.id;
                this.rfNumber = rf.rf_number;
                this.assignedTechnician = rf.assigned_technician;
                this.issuedDate = rf.created_at ? this.$dayjs(rf.created_at).format('YYYY-MM-DD, hh:mm:ss a') : '';
                this.rfStatus = rf.status;

                this.crateNumber = rf.crate_number;
                this.locationNumber = rf.location_number;

                if(!!this.repairForm.printed_qrcode) {
                    this.printedQrcode = true;
                } else {
                    this.printedQrcode = false;
                }

                if(!!rf.sales_order_id) {
                    this.soNumber = rf.sales_order_num;
                }

                if(!!rf.packing_list_id) {
                    this.plNumber = rf.packing_list_num;
                }

                if(!!rf.approved_for_cm) {
                    this.approvedForCm = !!rf.approved_for_cm;
                    this.cmNumber = rf.cm_number;
                    this.approvedCmBy = rf.approved_cm_by;
                }

                if(!!rf.approved_for_replacement) {
                    this.approvedForReplacement = !!rf.approved_for_replacement;
                    this.approvedForReplacementBy = rf.approved_for_replacement_by;
                    this.approvedForReplacementDate = this.$dayjs(rf.approved_for_replacement_at).format('YYYY-MM-DD, hh:mm:ss a');
                }


                let rr = this.repairForm.sc_receiving_report;
                if(!!rr) {
                    this.rrNumber = !!rr.rr_number ? rr.rr_number : '';
                    this.pulloutNumber = !!rr.pullout_number ? rr.pullout_number : '';

                    let contact = [];
                    let fullName = [];

                    if(rr.customer_id == 275) {
                        if(!!rr.sc_customer) {
                            if(!!rr.sc_customer.first_name) {
                                fullName.push(rr.sc_customer.first_name);
                            } if(!!rr.sc_customer.middle_name) {
                                fullName.push(rr.sc_customer.middle_name);
                            } if(!!rr.sc_customer.last_name) {
                                fullName.push(rr.sc_customer.last_name);
                            }

                            this.customerContactNumber = rr.sc_customer.contact_number;
                            this.customerAddress = rr.sc_customer.address;
                            this.customerEmal = rr.sc_customer.email;
                        }
                        this.companyName = rr.customer.company_name;
                        this.customerName = fullName.toString().replaceAll(',', ' ');

                    } else {
                        this.companyName = rr.customer.company_name;
                        this.customerContactNumber = contact.toString().replaceAll(',',' ');
                        this.customerName = `${rr.customer.first_name} ${rr.customer.middle_name} ${rr.customer.last_name}`;
                        let customerAdd = JSON.parse(rr.customer.shipping_addresses)
                        this.customerAddress = customerAdd[0].address;
                        this.customerEmal = rr.customer.email;
                        if(!!rr.customer.mobile_1) {
                            contact.push(rr.customer.mobile_1);
                        }
                        if(!!rr.customer.mobile_2) {
                            contact.push(rr.customer.mobile_2);
                        }
                        if(!!rr.customer.mobile_3) {
                            contact.push(rr.customer.mobile_3);
                        }
                        if(!!rr.customer.tel_1) {
                            contact.push(rr.customer.tel_1);
                        }
                        if(!!rr.customer.tel_2) {
                            contact.push(rr.customer.tel_2);
                        }
                        if(!!rr.customer.tel_3) {
                            contact.push(rr.customer.tel_3);
                        }
                        // if(!!rr.customer.first_name) {
                        //     fullName.push(rr.customer.first_name);
                        // }
                        // if(!!rr.customer.middle_name) {
                        //     fullName.push(rr.customer.middle_name);
                        // }
                        // if(!!rr.customer.last_name) {
                        //     fullName.push(rr.customer.last_name);
                        // }
                        // this.customerName = fullName.toString().replaceAll(',', ' ');
                        this.customerContactNumber = contact.toString().replaceAll(',', ' / ');
                    }

                    if(!!rf.sc_rf_movements && !!rf.sc_rf_movements.length > 0) {
                        this.rfMovements = rf.sc_rf_movements;
                        this.lastMovement.push(rf.sc_rf_movements.filter(Boolean).pop());
                    }
                }

                let rfi = this.repairForm.sc_repair_form_item;
                if(!!rfi) {
                    this.itemWarrantyType = rfi.warranty_type;
                    this.itemPurchaseDate = !!rfi.purchase_date ? rfi.purchase_date : '';
                    this.itemModel = rfi.model;
                    this.itemName = rfi.product_name;
                    this.itemCategory = rfi.category;

                    if(!!rfi.replacement_serial_number) {
                        this.itemSerial = rfi.replacement_serial_number;
                    } else {
                        this.itemSerial = rfi.serial;
                    }

                    this.itemAccessories = rfi.accessories;
                    this.itemOtherAccessory = rfi.other_accessory;
                    this.itemUnitCondition = rfi.unit_condition;
                    this.itemOtherUnitCondition = rfi.other_unit_condition;
                    this.itemDefectDescription = rfi.defect_description;
                    this.itemDefectRemarks = rfi.defect_remarks;
                    this.itemRepairStatus = rfi.repair_status;
                    this.itemRepairStartedDate = rfi.repair_started_at == null ? '' : this.$dayjs(rfi.repair_started_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.itemRepairCompletedDate = rfi.repair_completed_at == null  ? ''  : this.$dayjs(rfi.repair_completed_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.itemRepairCancelledDate = rfi.repair_cancelled_at == null  ? ''  : this.$dayjs(rfi.repair_cancelled_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.itemSymptomUponTested = rfi.symptom_upon_tested;
                    this.itemJobDone = rfi.job_done;
                    this.itemQaRemarks = rfi.qa_remarks;

                    if(!!rfi.warranty_reject_reason) {
                        this.itemWarrantyRejectReason = rfi.warranty_reject_reason;
                    }

                    if(!!rfi.qa_approved_by) {
                        this.itemQaApprovedBy = rfi.qa_approved_by;
                    }

                    if(!!rfi.qa_rejected_by) {
                        this.itemQaRejectedBy = rfi.qa_rejected_by;
                    }

                }

                let rf_estimations = this.repairForm.sc_rf_estimations;
                if(!!rf_estimations) {
                    this.quotations = rf_estimations;

                    let quotationsCount = this.quotations.length;

                    if(quotationsCount > 0) {
                        let rfEstimation = this.quotations[this.quotations.length - 1];

                        if(!!rfEstimation) {
                            this.rfEstId = rfEstimation.id;

                            this.estItems = rfEstimation.sc_rf_est_items;

                            if(!!rfEstimation.sales_quotation_id) {
                                this.sqNumber = rfEstimation.sales_quotation_number;
                            }
                        }
                    }
                }

                let parts_orders = this.repairForm.sc_parts_orders
                if(!!parts_orders && parts_orders.length > 0) {
                    this.partsOrderItems = parts_orders[parts_orders.length-1].sc_parts_order_items;
                    this.reasonForRequote = parts_orders[parts_orders.length-1].reason_for_requote;
                }

                let delivery = this.repairForm.sc_delivery
                if(!!delivery) {
                    this.rfDeliveryNumber =  delivery.dr_number;
                    this.rfDeliveredDate =  this.$dayjs(delivery.delivered_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.rfCollectedDate =  this.$dayjs(delivery.received_at).format('YYYY-MM-DD, hh:mm:ss a');
                    this.rfDeliveryProcessedBy =  delivery.processed_by;
                }

                let rf_sales_quotations = this.repairForm.sales_quotations;
                if(rf_sales_quotations) {
                    this.salesQuotations = rf_sales_quotations;
                    if (this.salesQuotations.length > 0) {
                        let lastQuotation = this.salesQuotations[this.salesQuotations.length - 1];
                        if (lastQuotation) {
                            this.currentSalesQuotation.push(lastQuotation);
                        }

                        if (this.salesQuotations.length > 1) {
                            this. oldSalesQuotations =  this.salesQuotations.slice(0,(this.salesQuotations.length - 1))
                        }

                    }
                }
            }
        }
    },
    methods:{
        closeRfInquiryDialog() {
            this.repairForm = [];
            this.$emit('closeInquiryDetailsDialog', true);
            this.$store.commit('closeRfInquiryDialog');
            this.clearFields();
            this.loading = true;
        },
        clearFields() {
            this.rfNumber = '';
            this.assignedTechnician = '';
            this.issuedDate = '';
            this.rfStatus = '';
            this.rrNumber = '';
            this.pulloutNumber = '';
            this.companyName = '';
            this.customerName = '';
            this.customerContactNumber = '';
            this.customerAddress = '';
            this.customerEmal = '';
            this.rfEstId = '';
            this.partsOrderItems = [];
            this.quotations = [];
            this.itemRepairStatus = '';
            this.itemRepairStartedDate = '';
            this.itemRepairCompletedDate = '';
            this.rfDeliveryNumber = '';
            this.rfDeliveredDate = '';
            this.rfCollectedDate = '';
            this.rfDeliveryProcessedBy = '';
            this.itemWarrantyType = '';
            this.itemPurchaseDate = '';
            this.itemModel = '';
            this.itemName = '';
            this.itemCategory = '';
            this.itemSerial = '';
            this.itemAccessories = '';
            this.itemOtherAccessory = '';
            this.itemUnitCondition = '';
            this.itemOtherUnitCondition = '';
            this.itemDefectDescription = '';
            this.itemDefectRemarks = '';
            this.rfMovements = [];
            this.rfId = '';
            this.cmNumber = '';
            this.approvedCmBy = '';
            this.itemWarrantyRejectReason = '';
            this.approvedForReplacementBy = '';
            this.approvedForReplacementDate = '';
            this.approvedForCm = false;
            this.approvedForReplacement = false;
            this.itemQaApprovedBy = '';
            this.itemQaRejectedBy = '';
            this.lastMovement = [];
            this.sqNumber = '';
            this.soNumber = '';
            this.plNumber = '';
            this.locationNumber = '';
            this.itemSymptomUponTested = '';
            this.itemJobDone = '';
            this.salesQuotations = [];
            this.oldSalesQuotations = [];
            this.currentSalesQuotation = [];
            this.reasonForRequote = '';
        },
        getRfDetails() {
            this.repairForm = []
            this.$store.dispatch('searchRepairForm',this.repairFormId).then(res=>{
                this.repairForm = this.GET_SEARCH_REPAIR_FORM;
            })
        },
        date(date) {
            if(!!date) {
                return this.$dayjs(date).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        showQuotationItemsDialog(item) {
            this.quotationItemsDialog = true;
            if(!!item.sales_quotation_items) {
                ({sales_quotation_items:this.salesQuotationItems} = item);
            }
        },
        closeQuotationItemsDialog() {
            this.quotationItemsDialog = false;
        },
        showEstHistoryDialog() {
            this.$store.commit("showConversationDialog");
        },
        updatedBy(item) {
            if(!!item) {
                return item.updated_by.name;
            }

            return '';
        },
        printEstimationForm() {
            let doc = new this.$jspdf('p', 'mm', 'letter');

            doc.setFont(undefined, 'bold').setFontSize(13).text(6,8,"Sales Quotation Form")
            doc.setFont(undefined, 'normal').setFontSize(9).text(6,13,"RF#: "+this.rfNumber + " Crate# "+this.crateNumber+" Loc# "+this.locationNumber +"\n\nCompany Name: "+ this.companyName+"\n\nCustomer Name: "+ this.customerName+"\n\nWarranty Type: "+this.itemWarrantyType)
            doc.setFont(undefined, 'normal').setFontSize(9).text(140,13,"Model: "+this.itemModel+"\n\nName: "+this.itemName+"\n\nSerial Number: "+ this.itemSerial+"\n\nComplaint / Symptom: "+ this.itemDefectDescription);

            doc.autoTable({
                html:'#sq_items',
                theme: 'grid',
                startY: 42,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 15, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 59.9, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 23, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 35, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoTable({
                html: '#sq_footer',
                theme: 'grid',
                startY: 250,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 24, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 77, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 24, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 77, halign:'left', textColor: '#000000', fontSize: 8},
                }
            });
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        showExportDialog() {
            this.exportDialog = true;
        },
        closeExportDialog() {
            this.exportDialog = false;
        },
        // printed(){
        //     this.print_repair_form = false
        // },
        closeConfirmOverride(user_id, authenticated = false) {
            // console.log(authenticated)
            if (authenticated) {
                this.override.override_dialog = false;
                this.$store.commit('PRINT_RF_ID', this.rfId);
            } else {
                // swal.fire('Error!','- invalid password','error')
                this.override.override_dialog = false;
            }
        },

        closeConfirmOverrides(user_id, authenticated = false) {
            // console.log(authenticated)
            if (authenticated) {
                this.override.override_dialogs = false;
                this.downloadQrcode()
            } else {
                // swal.fire('Error!','- invalid password','error')
                this.override.override_dialogs = false;
            }
        },

        showOverride() {
            if(!!this.printedQrcode) {
                this.override.override_dialogs = true;
            } else {
                this.downloadQrcode();
            }
        },

        downloadQrcode() {
            let pdf = new this.$jspdf('l', 'mm', [25,110]);
            // let pdf = new this.$jspdf('l', 'mm', 'letter');
            const assetTagCanvas = document.querySelector('#qr canvas');
            let image = assetTagCanvas.toDataURL('image/jpeg');


            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            pdf.addImage(image, 'png', 3,3,17,17);

            // pdf.setFontSize(6);
            // pdf.text(`Customer: ${this.customerName}`, 22,5);

            pdf.setFontSize(6);
            pdf.text(`RF#: ${this.rfNumber}`, 22,6);

            pdf.setFontSize(6);
            pdf.text(`Model: ${this.itemModel}`, 22, 10);

            pdf.setFontSize(6);
            pdf.text(`Serial: ${this.itemSerial}`, 50, 6);

            pdf.setFontSize(6);
            pdf.text(`Category: ${this.itemCategory}`, 50, 10);

            pdf.setFontSize(6);
            pdf.text(this.companyName, 22, 14);

            pdf.setFontSize(6);
            pdf.text(this.customerName, 22, 18);


            if(!!this.customerName) {
                pdf.setFontSize(6);
                pdf.text(`Date: ${this.rfDate}`, 50, 18);
            } else {
                pdf.setFontSize(6);
                pdf.text(`Date: ${this.rfDate}`, 22, 18);
            }


            pdf.addImage(image, 'png', 78,3,17,17);

            // open pdf to another window
            pdf.output('dataurlnewwindow');

            // pdf.save(`${this.rfNumber}-Qrcode.pdf`); allow to download pdf
            let payload = {
                rfId: this.rfId
            }
            this.$store.dispatch('updatePrintedQrcode',payload).then((response) => {
                if(response.data.msg) {
                    this.getRfDetails(this.rfId);
                    swal.fire('', response.data.msg, 'success');
                }
            }).catch((e)=> {
                console.log(e)
            })
        },
    }
}
</script>

<style>

</style>
