import api from '../../../api'

export default {
    getCurrencies(params) {
      return api.get(`accounting/currencies/${params.url}`, { params })
    },
    postCurrencies(params) {
      return api.post(`accounting/currencies/${params.url}`, params)
    },
    putCurrencies(params) {
      return api.put(`accounting/currencies/${params.url}`, params)
    },
    deleteCurrencies(params) {
      return api.delete(`accounting/currencies/${params.url}`, params)
    },
}
