import api from '../../../api'
export default {

    getallcostitems(params) {
        return api.get('accounting/getCostItems', { params })
    },
    accountinggetitems(params) {
        return api.get('accounting/getItem', { params })
    },
    getexchangerate(params) {
        return api.get('accounting/getExchangeRate', { params })
    },
    updateitemcosting(params) {
        return api.post('accounting/updateItemCosting', params)
    },
    getallitemcostingsizes(params){
        return api.get('accounting/itemCostingSize', { params })
    },
    additemcostingsize(params) {
        return api.post(`accounting/itemCostingSize`, params)
    },
    showitemcostingsize(params) {
        return api.get(`accounting/itemCostingSize/${params}`)
    },
    storeitemcostingsize(params) {
        return api.post('accounting/itemCostingSize', params)
    },
    updateitemcostingsize(params) {
        return api.post(`accounting/itemCostingSize/`, params)
    },
    destroyitemcostingsize(params) {
        return api.delete(`accounting/itemCostingSize/${params}`)
    },
    importitemcosting(params) {
        return api.post(`accounting/importItemCosting`, params)
    },

}
