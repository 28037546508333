import repository from "@/api/Main/DropDowns/index.js";
export default {
    state: {
        user_select_list: [],
        salesman_positions_list: [],
        salesman_head_list: [],
        customer_list: [],
        items: {
            selection: [],
        },
        item_type: {
            selection: [],
        },
        item_category: {
            selection: [],
        },
        item_uom: {
            selection: [],
        },
        customers: {
            selection: [],
        },
        salesman: {
            selection: [],
        },
        customer_classifications: {
            selection: [],
        },
        customer_category: [],
        accounting_users: [],
        department_role:[],
        sales_by_region:[],
        vendors_list:[],
        items_exclude_sp:[],
        admin_supplier_selection:[],
        contact_person_selection: [],
        term_selection: [],
        admin_classification_selection:[],
        admin_sub_classification_selection:[],
        admin_location:[],
        admin_brand:[],
        status:[],
        employee_data_selection:[],
        marital_status:[],
        citizenship:[],
        job_title: [],
        contract_type: [],
        employee_superiors:[],
        admin_category_selection:[],
        job_title:[],
        citizenship:[],
        religion:[],
        uom_selection:[],
        admin_uom_selection:[],
        prf_selection:[],
        prf_stock_selection:[],
        admin_po_selection:[],
        admin_po_assets_selection:[],
        purpose_visit:[],
        backjob_com:[],
        service_customers_end_user:[],
        service_technicians:[],
        items_exclude_sp:[],
        items_exclude_sp: [],
        credit_memo_classification: [],
        credit_memo_selection:[],
        customer_with_credit_memo_selection:[],
        item_selection_exclude_sp:[],
        packing_list_num_selection:[],
        customer_with_packing_list_selection:[],
        admin_repair_category_selection:[],
        admin_repair_type_selection:[],
        exclusivity_type_selection:[],
        admin_po_num_selection:[],
        admin_po_num:[],
        employee_data:[],
        company_selection:[],
        employee_salary_type_selection:[],
        exclusivity_type_selection:[],
        check_lists:[],
        template:[],
        check_list_template:[],
        client_list : [],
        manpower_request_reason_type_selection :[],
        education_type_selection : [],
        experience_type_selection : [],
        application_form_job_title_selection : [],
        special_requirements_selection: [],
        special_skills_selection: [],
        store_branches_selection: [],
        employee_schedule_type_selection:[],
        employee_schedule_templates:[],
        enter_bill_selection:[],
        admin_jo_selection:[],
        admin_cef_selection:[],
        account_type_selection:[],
        admin_warehouse_code: [],
        back_job_selection: {},
        sample_form_selection:[],
        item_qa_checkeds:[],
        emojis:[],
        sp_replacements: [],
        get_specific_list: [],
        companies_selection: [],
        hr_user_selection: [],
        holidays_selection: [],
        hour_type_selection: [],
        holiday_type_selection: [],
        bio_metric_location_selection:[],
        hr_status_selection: [],
        timekeeping_period_selection: [],
        chart_of_accounts_selection: [],
        fundamental_selection: [],
        bank_selection: [],
        vendor_payment_item: [],
        booklets_selection: [],
        cheque_vouchers_selection: [],
        account_group_selection: [],
        transact_types_selection:[],
        pv_store_branches_selection:[],
        companies_selection: [],
        entry_types_selection: [],
        accounting_job_selection: [],
        pv_print_template: [],
        unassigned_spare_parts: [],
        po_items: [],
        clusters: {
            selection: [],
        },
        logistic_basket_selection: [],
        pv_companies_selection:[],
        sub_clusters: {
            selection: [],
        },
        pv_print_template: [],
        transaction_type_selection:[],
        vat_type_selection:[],
        container_selection:[],
        active_vendor_paybills:[],
        frequency_selection: [],
        rb_types: [],
        rb_booklets: [],
        service_payment_request_type:[],
        shipping_via: [],
        mother_broker_selection: [],
        admin_asset_condition_selection: [],
        inbound_outbound_type:[],
        store_branches_without_access: [],
        leave_type_selection: [],
        job_level_selection: [],
        application_status_selection: [],
        report_category: [],
        report_dropdown: [],
        vehicle_type: [],
        store_cash_in_selection: [],
        admin_items_selection: [],
        cash_flow_activity_selection: [],
        admin_fas_selection:[],
        debit_memo_selection: [],
        customer_with_debit_memo_selection: [],
        statement_of_account_selection: [],
        admin_delivery_addresses: [],
        company_classification_selection: [],
        system_detail: [],
        item_price_list_by_vendor:[],
        admin_basket_selection:[],
        picker_personnels: [],
        card_network_selection: [],
        bank_card_selection: [],
        dimension_type_selection: [],
        requisition_selection: [],
        po_with_asset_list: [],
        employee_turn_over: [],
        bank_masters_list: [],
        vendors_with_pending_card_payments: [],
        journal_voucher_type_selection: [],
        container_type_selection: [],
        fund_transfer_type_selection: [],
        pl_reload: false,
    },
    getters: {
        PO_WITH_ASSET_LIST: (state) => state.po_with_asset_list,
        GET_ALL_USERS_SELECT: (state) => state.user_select_list,
        GET_ALL_SALESMANPOSITIONS_SELECT: (state) =>
            state.salesman_positions_list,
        GET_ALL_SALESMANHEAD_SELECT: (state) => state.salesman_head_list,
        GET_CUSTOMERS_LIST_SELECTION: (state) => state.customer_list,
        GET_ITEM_SELECTION_DEFAULT: (state) => state.items.selection,
        GET_ITEM_SELECTION: (state) => state.items.selection,
        GET_CUSTOMERS_SELECTION: (state) => state.customers.selection,
        GET_SALESMAN_SELECTION: (state) => state.salesman.selection,
        GET_CLASSIFICATIONS_SELECTION: (state) =>
            state.customer_classifications.selection,
        GET_ACCOUNTING_USERS: (state) => state.accounting_users,
        GET_CUSTOMER_CATEGORY_SELECTION: (state) => state.customer_category,
        GET_DEPARTMENT_ROLE_LOAD: (state) => state.department_role,
        SALES_BY_REGION:(state) => state.sales_by_region,
        GET_ITEMS_EXCLUDE_SP: (state) => state.items_exclude_sp,
        GET_BACKJOB_COM: (state) => state.backjob_com,
        GET_CREDIT_MEMO_CLASSIFICATION: (state) => state.credit_memo_classification,
        GET_CREDIT_MEMO_SELECTION:(state) => state.credit_memo_selection,
        GET_CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state) => state.customer_with_credit_memo_selection,
        GET_ITEM_SELECTION_EXCLUDE_SP:(state) => state.item_selection_exclude_sp,
        GET_PACKING_LIST_NUM_SELECTION:(state) => state.packing_list_num_selection,
        GET_CUSTOMER_WITH_PACKING_LIST_SELECTION:(state) => state.customer_with_packing_list_selection,
        // GET_ALL_VENDORS_LIST:(state) => state.vendors_list,
        GET_ITEMS_EXCLUDE_SP:(state) => state.items_exclude_sp,
        GET_ADMIN_SUPPLIER_SELECTION:(state)=>state.admin_supplier_selection,
        GET_CONTACT_PERSON_SELECTION:(state)=>state.contact_person_selection,
        GET_TERM_SELECTION:(state)=>state.term_selection,
        GET_ADMIN_CLASSIFICATION_SELECTION:(state)=>state.admin_classification_selection,
        GET_ADMIN_SUB_CLASSIFICATION_SELECTION:(state)=>state.admin_sub_classification_selection,
        GET_ADMIN_LOCATION:(state)=>state.admin_location,
        GET_ADMIN_BRAND:(state)=>state.admin_brand,
        GET_STATUS_SELECTION:(state)=>state.status,
        GET_EMPLOYEE_DATA_SELECTION:(state)=>state.employee_data_selection,
        GET_MARITAL_STATUS_SELECTION:(state)=>state.marital_status,
        GET_CITIZENSHIP_SELECTION:(state)=>state.citizenship,
        GET_MARITAL_STATUS_SELECTION:(state)=>state.marital_status,
        GET_JOB_TITLE_SELECTION:(state)=>state.job_title,
        GET_CONTRACT_TYPE_SELECTION:(state)=>state.contract_type,
        GET_EMPLOYEE_SUPERIOR_SELECTION:(state)=>state.employee_superiors,
        GET_ADMIN_CATEGORY_SELECTION:(state)=>state.admin_category_selection,
        GET_JOB_TITLE_SELECTION:(state)=>state.job_title,
        GET_CITIZENSHIP_SELECTION:(state)=>state.citizenship,
        GET_RELIGION_SELECTION:(state)=>state.religion,
        GET_MARITAL_STATUS_SELECTION:(state)=>state.marital_status,
        GET_UOM_SELECTION:(state)=>state.uom_selection,
        GET_ADMIN_UOM_SELECTION:(state)=>state.admin_uom_selection,
        GET_PRF_SELECTION:(state)=>state.prf_selection,
        GET_PRF_STOCK_SELECTION:(state)=>state.prf_stock_selection,
        GET_ADMIN_PO_SELECTION:(state)=>state.admin_po_selection,
        GET_ADMIN_PO_ASSETS_SELECTION:(state)=>state.admin_po_assets_selection,
        GET_ITEMS_EXCLUDE_SP:(state) => state.items_exclude_sp,
        GET_ITEMS_EXCLUDE_SP: (state) => state.items_exclude_sp,
        GET_CREDIT_MEMO_CLASSIFICATION: (state) => state.credit_memo_classification,
        GET_CREDIT_MEMO_SELECTION:(state) => state.credit_memo_selection,
        GET_CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state) => state.customer_with_credit_memo_selection,
        GET_ITEM_SELECTION_EXCLUDE_SP:(state) => state.item_selection_exclude_sp,
        GET_PACKING_LIST_NUM_SELECTION:(state) => state.packing_list_num_selection,
        GET_CUSTOMER_WITH_PACKING_LIST_SELECTION:(state) => state.customer_with_packing_list_selection,
        GET_ADMIN_REPAIR_CATEGORY_SELECTION:(state)=>state.admin_repair_category_selection,
        GET_ADMIN_REPAIR_TYPE_SELECTION:(state)=>state.admin_repair_type_selection,
        GET_EXCLUSIVITY_TYPE_SELECTION: (state) => state.exclusivity_type_selection,
        GET_ADMIN_PO_NUM_SELECTION:(state)=>state.admin_po_num_selection,
        GET_ADMIN_PO_NUM:(state)=>state.admin_po_num,
        GET_ENTER_BILL_SELECTION:(state)=>state.enter_bill_selection,
        GET_ADMIN_JO_SELECTION:(state)=>state.admin_jo_selection,
        GET_ADMIN_CEF_SELECTION:(state)=>state.admin_cef_selection,
        GET_ADM_WAREHOUSE_CODE_DROPDOWN:(state)=>state.admin_warehouse_code,
        GET_CHECK_LISTS_SELECTION:(state) => state.check_lists,
        GET_ITEM_QA_CHECKED:(state) => state.item_qa_checkeds,
        GET_TEMPLATE_SELECTION:(state) => state.template,
        GET_CHECK_LIST_TEMPLATE_SELECTION:(state) => state.check_list_template,

        GET_CLIENT_LIST_SELECTION:(state)=>state.client_list,
        GET_MANPOWER_REQUEST_REASON_TYPE_SELECTION:(state)=>state.manpower_request_reason_type_selection,
        GET_EDUCATION_TYPE_SELECTION:(state)=>state.education_type_selection,
        GET_EXPERIENCE_TYPE_SELECTION:(state)=>state.experience_type_selection,
        GET_APPLICATION_FORM_JOB_TITLE_SELECTION:(state)=>state.application_form_job_title_selection,
        GET_SPECIAL_REQUIREMENTS_SELECTION:(state)=>state.special_requirements_selection,
        GET_SPECIAL_SKILLS_SELECTION:(state)=>state.special_skills_selection,
        GET_STORE_BRANCHES_SELECTION:(state)=>state.store_branches_selection,
        GET_SAMPLE_FORM_SELECTION: (state) => state.sample_form_selection,
        GET_EMPLOYEE_SCHEDULE_TYPE_SELECTION: (state)=> state.employee_schedule_type_selection,
        GET_EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION: (state) => state.employee_schedule_templates,
        GET_EMOJIS: (state) => state.emojis,
        GET_BACKJOB_SELECTION: (state) => state.back_job_selection,
        GET_SP_REPLACEMENT_SELECTION: (state) => state.sp_replacements,
        GET_SPECIFIC_CHECK_LISTS_SELECTION:(state) => state.get_specific_list,
        GET_HR_USER_SELECTION:(state) => state.hr_user_selection,
        GET_HOLIDAYS_SELECTION:(state) => state.holidays_selection,
        GET_HOUR_TYPE_SELECTION:(state) => state.hour_type_selection,
        GET_HOLIDAY_TYPE_SELECTION:(state) => state.holiday_type_selection,
        GET_BIO_METRIC_LOCATION_SELECTION:(state)=>state.bio_metric_location_selection,
        GET_HR_STATUS_SELECTION:(state)=>state.hr_status_selection,
        GET_TIMEKEEPING_PERIOD_SELECTION:(state)=>state.timekeeping_period_selection,
        GET_SPECIFIC_CHECK_LISTS_SELECTION: (state) => state.get_specific_list,
        GET_ADMIN_PO_NUM_SELECTION:(state)=>state.admin_po_num_selection,
        GET_ADMIN_PO_NUM:(state)=>state.admin_po_num,
        GET_ENTER_BILL_SELECTION:(state)=>state.enter_bill_selection,
        GET_ADMIN_JO_SELECTION:(state)=>state.admin_jo_selection,
        GET_ADMIN_CEF_SELECTION:(state)=>state.admin_cef_selection,
        GET_ACCOUNT_TYPES_SELECTION:(state)=>state.account_type_selection,
        GET_CHART_OF_ACCOUNTS_SELECTION:(state)=>state.chart_of_accounts_selection,
        GET_FUNDAMENTAL_SELECTION:(state)=>state.fundamental_selection,
        GET_BANK_SELECTION:(state)=>state.bank_selection,
        GET_VENDOR_PAYMENT_ITEM:(state)=>state.vendor_payment_item,
        GET_BOOKLETS_SELECTION:(state)=>state.booklets_selection,
        GET_CHEQUE_VOUCHERS_SELECTION:(state)=>state.cheque_vouchers_selection,
        GET_ACCOUNT_GROUP_SELECTION:(state)=>state.account_group_selection,
        GET_TRANSACT_TYPES_SELECTION:(state)=>state.transact_types_selection,
        GET_COMPANY_SELECTION:(state)=>state.company_selection,
        GET_PV_STORE_BRANCHES_SELECTION:(state)=>state.pv_store_branches_selection,
        GET_COMPANIES_SELECTION: (state) => state.companies_selection,
        GET_ENTRY_TYPES_SELECTION: (state) => state.entry_types_selection,
        GET_ACCOUNTING_JOB_SELECTION: (state) => state.accounting_job_selection,
        GET_PV_PRINT_TEMPLATE: (state) => state.pv_print_template,
        GET_UNASSIGNED_SPARE_PARTS: (state) => state.unassigned_spare_parts,
        GET_CLUSTER_SELECTION: (state) => state.clusters.selection,
        GET_PV_COMPANIES_SELECTION: (state) => state.pv_companies_selection,
        GET_SUB_CLUSTER_SELECTION: (state) => state.sub_clusters.selection,
        GET_PV_PRINT_TEMPLATE: (state) => state.pv_print_template,
        GET_TRANSACTION_TYPE_SELECTION: (state) => state.transaction_type_selection,
        GET_VAT_TYPE_SELECTION: (state) => state.vat_type_selection,
        GET_CONTAINER_SELECTION: (state) => state.container_selection,
        GET_ACTIVE_VENDOR_PAYBILLS: (state) => state.active_vendor_paybills,
        // GET_EMPLOYEE_SELECTION: (state) => state.employee_data,
        GET_FREQUENCY_SELECTION: (state) => state.frequency_selection,
        GET_RB_TYPES: (state) => state.rb_types,
        GET_RB_BOOKLETS: (state) => state.rb_booklets,
        GET_SERVICE_PAYMENT_REQUEST_TYPE: (state) => state.service_payment_request_type,
        GET_SHIPPING_VIA: (state) => state.shipping_via,
        GET_MOTHER_BROKER_SELECTION: (state) => state.mother_broker_selection,
        GET_ADMIN_ASSET_CONDITION_SELECTION: (state) => state.admin_asset_condition_selection,
        GET_ADMIN_PO_ITEM_SELECTION: (state) => state.po_items,
        GET_INBOUND_OUTBOUND_TYPE:(state)=>state.inbound_outbound_type,
        GET_STORE_BRANCHES_WITHOUT_ACCESS: (state) => state.store_branches_without_access,
        GET_LEAVE_TYPE_SELECTION:(state)=>state.leave_type_selection,
        GET_EMPLOYEE_DATA:(state)=>state.employee_data,
        GET_JOB_LEVEL_SELECTION:(state)=>state.job_level_selection,
        GET_APPLICATION_STATUS_SELECTION:(state)=>state.application_status_selection,
        GET_SERVICE_CUSTOMERS_END_USER:(state)=>state.service_customers_end_user,
        GET_REPORT_CATEGORY:(state)=>state.report_category,
        GET_REPORT_DROPDOWN:(state)=>state.report_dropdown,
        GET_EMPLOYEE_SALARY_TYPE_SELECTION:(state)=>state.employee_salary_type_selection,
        GET_LOGISTIC_BASKET_SELECTION:(state) => state.logistic_basket_selection,
        GET_VEHICLE_TYPE_SELECTION:(state)=>state.vehicle_type,
        GET_STORE_CASH_IN_SELECTION:(state)=>state.store_cash_in_selection,
        GET_ADMIN_ITEMS_SELECTION:(state)=>state.admin_items_selection,
        GET_CASH_FLOW_ACTIVITY_SELECTION:(state)=>state.cash_flow_activity_selection,
        GET_ADMIN_FAS_SELECTION:(state)=>state.admin_fas_selection,
        GET_DEBIT_MEMO_SELECTION: (state)=>state.debit_memo_selection,
        GET_CUSTOMER_WITH_DEBIT_MEMO_SELECTION: (state) => state.customer_with_debit_memo_selection,
        GET_ALL_STATE:(state) => state,
        GET_ADMIN_DELIVERY_ADDRESSES: (state) => state.admin_delivery_addresses,
        GET_COMPANY_CLASSIFICATION_SELECTION: (state) => state.company_classification_selection,
        GET_SYSTEM_DETAIL_SELECTION: (state) => state.system_detail,
        GET_ITEM_PRICE_LIST_BY_VENDOR:(state)=>state.item_price_list_by_vendor,
        GET_ADMIN_BASKET_SELECTION:(state)=>state.admin_basket_selection,
        GET_PICKER_PERSONNELS: (state) => state.picker_personnels,
        GET_CARD_NETWORK_SELECTION: (state) => state.card_network_selection,
        GET_BANK_CARD_SELECTION: (state) => state.bank_card_selection,
        GET_DIMENSION_TYPE_SELECTION: (state) => state.dimension_type_selection,
        GET_REQUISITION_SELECTION: (state) => state.requisition_selection,
        GET_EMPLOYEE_FOR_TURN_OVER: (state) => state.employee_turn_over,
        GET_BANK_MASTERS_LIST: (state) => state.bank_masters_list,
        GET_VENDORS_WITH_PENDING_CARD_PAYMENTS: (state) => state.vendors_with_pending_card_payments,
        GET_JOURNAL_VOUCHER_TYPE_SELECTION: (state) => state.journal_voucher_type_selection,
        GET_CONTAINER_TYPE_SELECTION: (state) => state.container_type_selection,
        GET_PACKING_LIST_RELOAD: (state) => state.pl_reload,
        GET_FUND_TRANSFER_TYPE_SELECTION: (state) => state.fund_transfer_type_selection,
    },
    actions: {
        getAllSelectUsers({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .userselectlist()
                    .then((response) => {
                        commit("USERS_LIST_LOAD", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSelectionDepartmentRole({commit}){

                repository
                    .department_role()
                    .then((response) => {

                        commit("DEPARTMENT_ROLE_LOAD", response.data);

                    })
                    .catch((e) => {

                    });


        },
        getALLSalesmanPosition({ commit }) {
            repository.salesmanpositions().then((response) => {
                commit("SALESMAN_POSITIONS_LOAD", response.data);
            });
        },
        getAllsalesmanSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .salesmanSelection()
                    .then((response) => {
                        commit("SALESMAN_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAllSalesmanHead({ commit }) {
            repository.salesmanhead().then((response) => {
                commit("SALESMAN_HEAD_LOAD", response.data);
            });
        },
        async getAllCustomers({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .customersSelection(payload)
                    .then((response) => {
                        commit("CUSTOMERS_LIST_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllitemSelectionDefault({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .itemselectiondefault()
                    .then((response) => {
                        commit("ITEM_SELECTION_DEFAULT", response.data.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async itemselection({ commit },payload){
            return new Promise((resolve, reject) => {
                repository
                    .itemselection(payload)
                    .then((response) => {
                        commit("ITEM_SELECTION", response.data.data);
                        resolve(response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
                })
        },
        async getAllcustomersSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .customersSelection(payload)
                    .then((response) => {
                        commit("CUSTOMERS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAllcustomerClassificationSelection({ commit }) {
            repository
                .getallcustomerclassificationselection()
                .then((response) => {
                    commit("CLASSIFICATIONS_SELECTION", response.data.data);
                });
        },
        getallcustomerCategory({ commit }) {
            repository.getallcustomerCategory().then((response) => {
                commit("CUSTOMER_CATEGORY_SELECTION", response.data.data);
            });
        },
        async getAccountingUsers({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .accountingusers()
                    .then((response) => {
                        commit("ACCOUNTING_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getActualWarehouseCodes({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getactualwarehousecodes()
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getSalesByRegionReports({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getSalesByRegionReports()
                    .then((response) => {
                        commit("SALES_BY_REGION", response.data.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminSupplierSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminSupplierSelection(payload)
                    .then((response) => {
                        commit("ADMIN_SUPPLIER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getContactPersonSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getContactPersonSelection(payload)
                    .then((response) => {
                        commit("CONTACT_PERSON_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getTermSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getTermSelection(payload)
                    .then((response) => {
                        commit("TERM_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async dropdownGet({ commit },payload) {
            return await new Promise((resolve, reject) => {
                repository
                    .dropdownGet(payload)
                    .then((response) => {
                       resolve(response)
                       commit('ITEMS_EXCLUDE_SP',response.data)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        // async getVendorsSelection({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         repository
        //             .getVendorsSelection()
        //             .then((response) => {
        //                 commit("VENDORS_LIST_SELECTION", response.data);
        //                 resolve(response)
        //             })
        //             .catch((e) => {
        //                 reject();
        //             });
        //     });
        // },
        async getBackJobDropDown({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getBackJobDropDown(payload)
                    .then((response) => {
                        commit('BACKJOB_COM',response.data)
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async creditMemoClassificationDropdown({ commit }) {
            return new Promise((resolve, reject) => {
                repository.creditMemoClassification().then(response => {
                    commit('CREDIT_MEMO_CLASSIFICATION', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async getAllCreditMemoSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCreditMemoSelection(payload)
                    .then((response) => {
                        commit('CREDIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminClassificationSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminClassificationSelection()
                    .then((response) => {
                        commit("ADMIN_CLASSIFICATION_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminSubClassificationSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminSubClassificationSelection()
                    .then((response) => {
                        commit("ADMIN_SUB_CLASSIFICATION_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithCMSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCustomerWithCMSelection(payload)
                    .then((response) => {
                        commit('CUSTOMER_WITH_CREDIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminLocationSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminLocationSelection(payload)
                    .then((response) => {
                        commit("ADMIN_LOCATION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithCMSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCustomerWithCMSelection(payload)
                    .then((response) => {
                        commit('CUSTOMER_WITH_CREDIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminBrandSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminBrandSelection()
                    .then((response) => {
                        commit("ADMIN_BRAND", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async itemselectionExcludeSp({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .itemselectionExcludeSp(payload)
                    .then((response) => {
                        commit('ITEM_SELECTION_EXCLUDE_SP',response.data)
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminCategories(payload)
                    .then((response) => {
                        commit("ADMIN_CATEGORY_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllPackingListNumSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllPackingListNumSelection(payload)
                    .then((response) => {
                        commit('PACKING_LIST_NUM_SELECTION',response.data)
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithPLSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .getAllCustomerWithPLSelection(payload)
                .then((response) => {
                    commit('CUSTOMER_WITH_PACKING_LIST_SELECTION', response.data)
                    resolve(response)
                })
                .catch((e) => {
                    reject();
                });
            });
        },
        getAdminBrandSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminBrandSelection()
                    .then((response) => {
                        commit("ADMIN_BRAND", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminCategories(payload)
                    .then((response) => {
                        commit("ADMIN_CATEGORY_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getStatus({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getStatus(payload)
                    .then((response) => {
                        commit("STATUS", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithPLSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCustomerWithPLSelection(payload)
                    .then((response) => {
                        commit('CUSTOMER_WITH_PACKING_LIST_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeDetails({ commit }){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeDetails()
                    .then((response) => {
                        commit("EMPLOYEE_DATA", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeReligionSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeReligionSelection()
                    .then((response) => {
                        commit("RELIGION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeMaritalStatusSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeMaritalStatusSelection()
                    .then((response) => {
                        commit("MARITAL_STATUS", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeCitizenshipSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeCitizenshipSelection()
                    .then((response) => {
                        commit("CITIZENSHIP", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeJobTitleSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeJobTitleSelection()
                    .then((response) => {
                        commit("JOB_TITLE", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getitemUomSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getitemUomSelection()
                    .then((response) => {
                        commit("UOM_SELECTION", response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminItemUomSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminItemUomSelection()
                    .then((response) => {
                        commit("ADMIN_UOM_SELECTION", response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeContractTypeSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeContractTypeSelection()
                    .then((response) => {
                        commit("CONTRACT_TYPE", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPRFSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getPRFSelection()
                    .then((response) => {
                        commit("PRF_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeSuperiorSelection({commit}){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeSuperiorSelection()
                    .then((response) => {
                        commit("EMPLOYEE_SUPERIOR_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminPoSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminPoSelection(payload)
                    .then((response) => {
                        commit("ADMIN_PO_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPONumberAssetsSelectionn({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getPONumberAssetsSelectionn(payload)
                    .then((response) => {
                        commit("ADMIN_PO_ASSETS_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        repairCategorySelection({commit}){
            return new Promise((resolve,reject)=>{
                repository.repairCategorySelection()
                .then((response)=>{
                    commit('ADMIN_REPAIR_CATEGORY_SELECTION',response.data);
                }).catch((e)=>{
                    console.log(e);
                    reject(e);
                })
            })
        },
        repairTypeSelection({commit}){
            return new Promise((resolve,reject)=>{
                repository.repairTypeSelection()
                .then((response)=>{
                    commit('ADMIN_REPAIR_TYPE_SELECTION',response.data);
                }).catch((e)=>{
                    console.log(e);
                    reject(e);
                })
            })
        },
        getManpowerRequestReasonTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getManpowerRequestReasonTypeSelection(payload)
                    .then((response) => {
                        commit("MANPOWER_REQUEST_REASON_TYPE_SELECTION", response.data);
                        resolve(response.data);
                   })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPurposeOfVisit({ commit }, payload) {

            return new Promise((resolve, reject) => {
                repository
                    .getPurposeOfVisit(payload)
                    .then((response) => {
                        commit("PURPOSE_OF_VISIT", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getServiceCustomers({commit},payload){
            await new Promise((resolve, reject) => {
                repository
                    .getServiceCustomers(payload)
                    .then((response) => {
                        let customers = response.data.customers.concat(response.data.end_users)
                        commit("SERVICE_CUSTOMERS_END_USER", customers);
                    });
                });
            },

        getExclusivityTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getExclusivityTypeSelection(payload)
                    .then((response) => {
                        commit("EXCLUSIVITY_TYPE_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEducationTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEducationTypeSelection(payload)
                    .then((response) => {
                        commit("EDUCATION_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getExperienceTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getExperienceTypeSelection(payload)
                    .then((response) => {
                        commit("EXPERIENCE_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getApplicationFormJobTitlesSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getApplicationFormJobTitlesSelection(payload)
                    .then((response) => {
                        commit("APPLICATION_FORM_JOB_TITLE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSpecialRequirementsSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSpecialRequirementsSelection(payload)
                    .then((response) => {
                        commit("SPECIAL_REQUIREMENTS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSpecialSkillsSelectionSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSpecialSkillsSelection(payload)
                    .then((response) => {
                        commit("SPECIAL_SKILLS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getStoreBranchesSelectionSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getStoreBranchesSelection(payload)
                    .then((response) => {
                        commit("STORE_BRANCHES_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getadminPurchaseOrderNumSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getadminPurchaseOrderNumSelection(payload)
                    .then((response) => {
                        commit("ADMIN_PO_NUM_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getLists({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getLists(payload).then((response)=>{
					if(response.status == 200){
                        commit('CHECK_LISTS',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getTemplate({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getTemplate(payload).then((response)=>{
					if(response.status == 200){
                        commit('TEMPLATE',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getCheckListTemplate({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getCheckListTemplate(payload).then((response)=>{
					if(response.status == 200){
                        commit('CHECK_LIST_TEMPLATE',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getEmployeeDataDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeDataDropdown(payload)
                    .then((response) => {
                        commit("EMPLOYEE_DATA_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSelectedAdminStockfromPO({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSelectedAdminStockfromPO(payload)
                    .then((response) => {
                        commit("PRF_STOCK_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },

        getadminPurchaseOrderNum({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getadminPurchaseOrderNum(payload)
                    .then((response) => {
                        commit("ADMIN_PO_NUM", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getfindPRFfromPO({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getfindPRFfromPO(payload)
                    .then((response) => {
                        commit("PRF_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminStockfromPO({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminStockfromPO(payload)
                    .then((response) => {
                        commit("PO_ITEM_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSelectedAdminStockfromPO({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSelectedAdminStockfromPO(payload)
                    .then((response) => {
                        commit("PRF_STOCK_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSampleFormSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getSampleFormSelection(payload)
                    .then((response) => {
                        commit("SAMPLE_FORM_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminEnteBillSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminEnteBillSelection(payload)
                    .then((response) => {
                        commit("ENTER_BILL_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getCompanySelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getCompanySelection(payload)
                    .then((response) => {
                        commit("COMPANY_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminJOSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminJOSelection(payload)
                    .then((response) => {
                        commit("ADMIN_JO_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeSalaryTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeSalaryTypeSelection(payload)
                    .then((response) => {
                        commit("EMPLOYEE_SALARY_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getClientListSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getClientListSelection(payload)
                    .then((response) => {
                        commit("CLIENT_LIST_SELECTION", response.data);
                        resolve(response.data);
                  })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeScheduleType({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeScheduleType(payload)
                    .then((response) => {
                        commit("EMPLOYEE_SCHEDULE_TYPE_SELECTION", response.data);
                        resolve(response.data);
                  })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEmployeeScheduleTemplates({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeScheduleTemplates(payload)
                    .then((response) => {
                        commit("EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION", response.data);
                        resolve(response.data);
                  }).catch((e) => {
                    reject();
                });
            });
        },
        getAdminCEFSelection({commit},payload){
                return new Promise((resolve, reject) => {
                    repository
                        .getAdminCEFSelection(payload)
                        .then((response) => {
                        commit("ADMIN_CEF_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getItemChecked({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getItemChecked(payload).then((response)=>{
					if(response.status == 200){
                        commit('ITEM_QA_CHECKED',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getEmoji({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getEmoji(payload).then((response)=>{
                    if(response.status == 200){
                        commit('EMOJIS',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getBackJobSelection({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getBackJobSelection(payload).then((response)=>{
                    if(response.status == 200){
                        commit('BACKJOB_SELECTION',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getSpReplacementsSelection({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getSpReplacementsSelection(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SP_REPLACEMENT_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSpecificListSelection({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getSpecificListSelection(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SPECIFIC_CHECK_LISTS_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async getHrUsers({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .hrusers()
                    .then((response) => {
                        commit("HR_USER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAccountTypes({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAccountTypes(payload)
                    .then((response) => {
                        commit("ACCOUNT_TYPES_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminWarehouseCodeDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminWarehouseCodeDropdown(payload)
                    .then((response) => {
                        commit("ADM_WAREHOUSE_CODE_DROPDOWN", response.data.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getCOASelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getCOASelection(payload)
                    .then((response) => {
                        commit("CHART_OF_ACCOUNTS_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getFundamentals({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getFundamentals(payload)
                    .then((response) => {
                        commit("FUNDAMENTAL_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getBankSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getBankSelection(payload)
                    .then((response) => {
                        commit("BANK_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getVendorPaymentItems({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getVendorPaymentItems(payload)
                    .then((response) => {
                        commit("VENDOR_PAYMENT_ITEM", response.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getBookletsSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getBookletsSelection(payload)
                    .then((response) => {
                        resolve(response.data)
                        commit("BOOKLETS_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getChequeVouchersSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getChequeVouchersSelection(payload)
                    .then((response) => {
                        commit("CHEQUE_VOUCHERS_SELECTION", response.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAccountGroup({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAccountGroup(payload)
                    .then((response) => {
                        commit("ACCOUNT_GROUP_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getTransactTypesSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getTransactTypesSelection(payload)
                    .then((response) => {
                        commit("TRANSACT_TYPES_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPaymentVoucherBranchesSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getPaymentVoucherBranchesSelection(payload)
                    .then((response) => {
                        commit("PV_STORE_BRANCHES_SELECTION", response.data);
                        resolve(response.data);
                    });
            });
        },
        getCompaniesDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getCompaniesDropdown(payload)
                    .then((response) => {
                        commit("COMPANIES_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getHolidays({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getHolidays(payload).then((response)=>{
                    commit('HOLIDAYS_SELECTION', response.data)
                    resolve(response.data);
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getHourTypes({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getHourTypes(payload).then((response)=>{
                    commit('HOUR_TYPE_SELECTION', response.data)
                    resolve(response.data);
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getHolidayTypes({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getHolidayTypes(payload).then((response)=>{
                    commit('HOLIDAY_TYPE_SELECTION', response.data)
                    resolve(response.data);
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getBioMetricLocationSelection({commit}){
            return new Promise((resolve, reject)=>{
                repository.getBioMetricLocationSelection().then((response)=>{
                    commit('BIO_METRIC_LOCATION_SELECTION', response.data)
                    resolve(response.data);
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getStatusHR({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getStatusHR(payload)
                    .then((response) => {
                        commit("HR_STATUS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getEntryTypesSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEntryTypesSelection(payload)
                    .then((response) => {
                        commit("ENTRY_TYPES_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getTimekeepingPeriods({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getTimekeepingPeriods(payload)
                    .then((response) => {
                        commit("TIMEKEEPING_PERIOD_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAccountingJobSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAccountingJobSelection(payload)
                    .then((response) => {
                        commit("ACCOUNTING_JOB_SELECTION", response.data);
                    }).catch( error => {
                        reject(error);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPVPrintTemplate({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getPVPrintTemplate(payload)
                    .then((response) => {
                        commit("PV_PRINT_TEMPLATE", response.data);
                    }).catch( error => {
                        reject(error);
                    })
                })
            },
        getUnassignedSpareParts({commit}) {
            return new Promise((resolve, reject) => {
                repository.getunassignedspareparts().then( response => {
                    if (response.status == 200) {
                        resolve(response);
                        commit('UNASSIGNED_SPARE_PARTS', response.data);
                    }
                }).catch( error => {
                    reject(error);
                })
            })
        },
        getClusterSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getClusterSelection()
                    .then((response) => {
                        commit("CLUSTER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPvCompanies({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getPvCompanies(payload)
                    .then((response) => {
                        commit("PV_COMPANIES_SELECTION", response.data);
                      resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getSubClusterSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getSubClusterSelection(payload)
                    .then((response) => {
                        commit("SUB_CLUSTER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject(e);
                        console.error(e)
                    });
            });
        },
        getTransactionTypeSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getTransactionTypeSelection()
                    .then((response) => {
                        commit("TRANSACTION_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getLeaveTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getLeaveTypeSelection(payload)
                    .then((response) => {
                        commit("LEAVE_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getVatTypeSelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getVatTypeSelection()
                    .then((response) => {
                        commit("VAT_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getJobLevelSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getJobLevelSelection(payload)
                    .then((response) => {
                        commit("JOB_LEVEL_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getContainer({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getContainer(payload)
                    .then((response) => {
                        commit("CONTAINER_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getApplicationStatusSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getApplicationStatusSelection(payload)
                    .then((response) => {
                        commit("APPLICATION_STATUS_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        dropdownsApiGet({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.dropdownsApiGet(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                        commit(payload.mutation, response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getLogisticBasket({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getLogisticBasket(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('LOGISTIC_BASKET_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getActiveVendorPaybills({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getActiveVendorPaybills(payload).then((response) => {
                    if (response.status == 200) {
                        commit("ACTIVE_VENDOR_PAYBILLS", response.data);
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getFrequencySelection({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getFrequencySelection()
                    .then((response) => {
                        commit("FREQUENCY_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getLeaveTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getLeaveTypeSelection(payload)
                    .then((response) => {
                        commit("LEAVE_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getJobLevelSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getJobLevelSelection(payload)
                    .then((response) => {
                        commit("JOB_LEVEL_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getRecurringBillTypes({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getRecurringBillTypes()
                    .then((response) => {
                        commit("RB_TYPES", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getRecurringBillBooklets({ commit }) {
            return new Promise((resolve, reject) => {
                repository
                    .getRecurringBillBooklets()
                    .then((response) => {
                        commit("RB_BOOKLETS", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getApplicationStatusSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getApplicationStatusSelection(payload)
                    .then((response) => {
                        commit("APPLICATION_STATUS_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getServicePaymentRequestType({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getServicePaymentRequestType(payload).then((response) => {
                    if (response.status == 200) {
                        commit("SERVICE_PAYMENT_REQUEST_TYPE", response.data);
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getShippingVia({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getShippingVia(payload).then((response) => {
                    if (response.status == 200) {
                        commit("SHIPPING_VIA", response.data);
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getMotherBrokerSelection({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getMotherBrokerSelection(payload).then((response) => {
                    if (response.status == 200) {
                        commit("MOTHER_BROKER_SELECTION", response.data);
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAdminAssetConditionSelection({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getAdminAssetConditionSelection(payload).then((response) => {
                    if (response.status == 200) {
                        commit("ADMIN_ASSET_CONDITION_SELECTION", response.data);
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPOReceiving({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getPOReceiving(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        dropdownsApiGet({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.dropdownsApiGet(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response.data)
                        commit(payload.mutation, response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getDisplayLogisticBasket({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getDisplayLogisticBasket(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DISPLAY_LOGISTIC_BASKET_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getInboundOutBoundType({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getInboundOutBoundType(payload).then((response) => {
                    if (response.status == 200) {
                        commit("INBOUND_OUTBOUND_TYPE", response.data);
                       resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getStoreBranchesWithoutAccess({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getStoreBranchesWithoutAccess(payload).then((response) => {
                    if (response.status == 200) {
                        commit("STORE_BRANCHES_WITHOUT_ACCESS", response.data);
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getVehicleType({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getVehicleType(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('VEHICLE_TYPE_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAdminItemsSelection({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getAdminItemsSelection(payload).then((response) => {
                    if (response.status == 200) {
                        commit('ADMIN_ITEMS_SELECTION', response.data)
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        getCashFlowActivities({commit}, payload)
        {
            return new Promise((resolve, reject) =>{
                repository.getCashFlowActivities(payload).then((response) => {
                    if (response.status == 200) {
                        commit('CASH_FLOW_ACTIVITY_SELECTION', response.data)
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getFasRequesition({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getFasRequesition(payload).then((response) => {
                    if (response.status == 200) {
                        commit('ADMIN_FAS_SELECTION', response.data)

                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        async getAllDebitMemoSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllDebitMemoSelection(payload)
                    .then((response) => {
                        commit('DEBIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        async getAllCustomerWithDMSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAllCustomerWithDMSelection(payload)
                    .then((response) => {
                        commit('CUSTOMER_WITH_DEBIT_MEMO_SELECTION',response.data)
                       resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getReportCategory({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getReportCategory(payload)
                    .then((response) => {
                        commit("REPORT_CATEGORY", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getReportsDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getReportsDropdown(payload)
                    .then((response) => {
                        commit("REPORT_DROPDOWN", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
                });
            },
            async getAllLocationWarehouseSelection({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository
                .getAllLocationWarehouseSelection(payload)
                .then((response) => {
                    resolve(response)
                })
                    .catch((e) => {
                        reject();
                    });
                });
            },
            async getAdminDeliveryAddressSelection({ commit },payload) {
                return new Promise((resolve, reject) => {
                    repository
                    .getAdminDeliveryAddressSelection(payload)
                    .then((response) => {
                        commit("ADMIN_DELIVERY_ADDRESSES", response.data);
                        resolve(response)
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getCompanyClassificationDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getCompanyClassificationDropdown(payload)
                    .then((response) => {
                        commit("COMPANY_CLASSIFICATION_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        systemDetailDropdown({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .systemDetailDropdown(payload)
                    .then((response) => {
                        commit("SYSTEM_DETAIL_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getItemPriceListByVendor({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getItemPriceListByVendor(payload)
                    .then((response) => {
                        commit("ITEM_PRICE_LIST_BY_VENDOR", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getAdminBasketSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getAdminBasketSelection(payload)
                    .then((response) => {
                        commit("ADMIN_BASKET_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getPickerPersonnels({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getpickerpersonnels(payload)
                    .then((response) => {
                        commit('PICKER_PERSONNELS', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getCardNetworks({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getCardNetworks(payload)
                    .then((response) => {
                        commit('CARD_NETWORK_SELECTION', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getBankCards({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getBankCards(payload)
                    .then((response) => {
                        commit('BANK_CARD_SELECTION', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getDimensionTypeSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getDimensionTypeSelection(payload)
                    .then((response) => {
                        commit('DIMENSION_TYPE_SELECTION', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getAdminRequisitionSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminRequisitionSelection(payload)
                    .then((response) => {
                        commit('REQUISITION_SELECTION', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getPoWithAssetList({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getPoWithAssetList(payload)
                    .then((response) => {
                        commit('PO_WITH_ASSET_LIST', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getEmployeeForTurnOver({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getEmployeeForTurnOver(payload)
                    .then((response) => {
                        commit("EMPLOYEE_FOR_TURN_OVER", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getBankMastersList({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getBankMastersList(payload)
                    .then((response) => {
                        commit('BANK_MASTERS_LIST', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getVendorsWithPendingCardPayments({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getVendorsWithPendingCardPayments(payload)
                    .then((response) => {
                        commit('VENDORS_WITH_PENDING_CARD_PAYMENTS', response.data)
                        resolve(response);
                    }).catch((e) => {
                        reject();
                    })
            })
        },
        getJournalVoucherTypes({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getJournalVoucherTypes(payload)
                    .then((response) => {
                        commit("JOURNAL_VOUCHER_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getContainerTypes({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getContainerTypes(payload)
                    .then((response) => {
                        commit("CONTAINER_TYPE_SELECTION", response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
        getFundTransferTypeSelection({commit},payload){
            return new Promise((resolve, reject) => {
                repository
                    .getFundTransferTypeSelection(payload)
                    .then((response) => {
                        commit("FUND_TRANSFER_TYPE_SELECTION", response.data);
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        },
    },
    mutations: {
        MUTATE_STATEMENT_OF_ACCOUNT_SELECTION:(state,data)=>{
            state.statement_of_account_selection = data;
        },
        USERS_LIST_LOAD: (state, data) => {
            state.user_select_list = data;
        },
        SALESMAN_POSITIONS_LOAD: (state, data) => {
            state.salesman_positions_list = data;
        },
        SALESMAN_HEAD_LOAD: (state, data) => {
            state.salesman_head_list = data;
        },
        SALESMAN_SELECTION: (state, data) => {
            state.salesman.selection = data;
        },
        CUSTOMERS_LIST_SELECTION: (state, data) => {
            state.customer_list = data;
        },
        CLASSIFICATIONS_SELECTION: (state, data) => {
            state.customer_classifications.selection = data;
        },
        ITEM_SELECTION_DEFAULT: (state, data) => {
            state.items.selection = data;
        },
        ITEM_SELECTION: (state, data) => {
            state.items.selection = data;
        },
        CUSTOMERS_SELECTION: (state, data) => {
            state.customers.selection = data;
        },
        ACCOUNTING_SELECTION: (state, data) => {
            state.accounting_users = data;
        },
        CUSTOMER_CATEGORY_SELECTION: (state, data) => {
            state.customer_category = data;
        },
        DEPARTMENT_ROLE_LOAD: (state,data)=>{
            state.department_role = data;
        },
        SALES_BY_REGION:(state, data)=>{
            state.sales_by_region = data
        },
        // VENDORS_LIST_SELECTION:(state, data)=>{
        //     state.vendors_list = data
        // },
        ITEMS_EXCLUDE_SP:(state, data)=>{
            state.items_exclude_sp = data
        },
        BACKJOB_COM:(state, data)=>{
            state.backjob_com = data
        },
        CREDIT_MEMO_CLASSIFICATION: (state, data) => {
            state.credit_memo_classification = data
        },
        CREDIT_MEMO_SELECTION:(state, data)=>{
            state.credit_memo_selection = data
        },
        CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state, data)=>{
            state.customer_with_credit_memo_selection = data
        },
        ITEM_SELECTION_EXCLUDE_SP:(state, data)=>{
            state.item_selection_exclude_sp = data
        },
        PACKING_LIST_NUM_SELECTION:(state, data)=>{
            state.packing_list_num_selection = data
        },
        CUSTOMER_WITH_PACKING_LIST_SELECTION:(state, data)=>{
            state.customer_with_packing_list_selection = data
        },
        ADMIN_SUPPLIER_SELECTION:(state,data)=>{
            state.admin_supplier_selection = data
        },
        CONTACT_PERSON_SELECTION:(state,data)=>{
            state.contact_person_selection = data
        },
        TERM_SELECTION:(state,data)=>{
            state.term_selection = data
        },
        ADMIN_CLASSIFICATION_SELECTION:(state,data)=>{
            state.admin_classification_selection = data
        },
        ADMIN_SUB_CLASSIFICATION_SELECTION:(state,data)=>{
            state.admin_sub_classification_selection = data
        },
        ADMIN_LOCATION:(state,data)=>{
            state.admin_location = data
        },
        ADMIN_BRAND:(state,data)=>{
            state.admin_brand = data
        },
        ADMIN_CATEGORY_SELECTION:(state,data)=>{
            state.admin_category_selection = data
        },
        STATUS:(state,data)=>{
            state.status = data
        },
        EMPLOYEE_DATA:(state,data)=>{
            state.employee_data_selection = data
            state.employee_data_selection.unshift({'value':0,'text':'GENERAL ACCOUNTABILITY'})
        },
        MARITAL_STATUS:(state,data)=>{
            state.marital_status = data
        },
        CITIZENSHIP:(state,data)=>{
            state.citizenship = data
        },
        RELIGION:(state,data)=>{
            state.religion = data
        },
        JOB_TITLE:(state,data)=>{
            state.job_title = data
        },
        CONTRACT_TYPE:(state,data)=>{
            state.contract_type = data
        },
        EMPLOYEE_SUPERIOR_SELECTION:(state,data)=>{
            state.employee_superiors = data
        },
        UOM_SELECTION:(state,data)=>{
            state.uom_selection = data
        },
        ADMIN_UOM_SELECTION:(state,data)=>{
            state.admin_uom_selection = data
        },
        PRF_SELECTION:(state,data)=>{
            state.prf_selection = data;
        },
        PO_ITEM_SELECTION:(state,data)=>{
            state.po_items = data;
        },
        PRF_STOCK_SELECTION:(state,data)=>{
            state.prf_stock_selection = data;
        },
        ADMIN_PO_SELECTION:(state,data)=>{
            state.admin_po_selection = data;
        },
        JOB_LEVEL_SELECTION:(state,data)=>{
            state.job_level_selection = data;
        },
        LEAVE_TYPE_SELECTION:(state,data)=>{
            state.leave_type_selection = data;
            state.leave_type_selection.push({'value':-1, 'text':'Others', 'color': '#f44336'})
        },
        APPLICATION_STATUS_SELECTION:(state,data)=>{
            state.application_status_selection = data;
        },
        ADMIN_PO_ASSETS_SELECTION:(state,data)=>{
            state.admin_po_assets_selection = data;
        },
        CREDIT_MEMO_CLASSIFICATION: (state, data) => {
            state.credit_memo_classification = data
        },
        CREDIT_MEMO_SELECTION:(state, data)=>{
            state.credit_memo_selection = data
        },
        CUSTOMER_WITH_CREDIT_MEMO_SELECTION:(state, data)=>{
            state.customer_with_credit_memo_selection = data
        },
        ITEM_SELECTION_EXCLUDE_SP:(state, data)=>{
            state.item_selection_exclude_sp = data
        },
        PACKING_LIST_NUM_SELECTION:(state, data)=>{
            state.packing_list_num_selection = data
        },
        CUSTOMER_WITH_PACKING_LIST_SELECTION:(state, data)=>{
            state.customer_with_packing_list_selection = data
        },
        ADMIN_REPAIR_CATEGORY_SELECTION:(state,data)=>{
            state.admin_repair_category_selection = data
        },
        ADMIN_REPAIR_TYPE_SELECTION:(state,data)=>{
            state.admin_repair_type_selection = data
        },
        EXCLUSIVITY_TYPE_SELECTION:(state, data)=>{
            state.exclusivity_type_selection = data
        },
        ADMIN_PO_NUM_SELECTION:(state,data)=>{
            state.admin_po_num_selection = data
        },
        ADMIN_PO_NUM:(state,data)=>{
            state.admin_po_num = data
        },
        CHECK_LISTS:(state,data)=>{
            state.check_lists = data
        },
        TEMPLATE:(state,data)=>{
            state.template = data
        },
        CHECK_LIST_TEMPLATE:(state,data)=>{
            state.check_list_template = data
        },
        CLIENT_LIST_SELECTION:(state,data)=>{
            state.client_list = data;
        },
        MANPOWER_REQUEST_REASON_TYPE_SELECTION:(state,data)=>{
            state.manpower_request_reason_type_selection = data;
        },
        EDUCATION_TYPE_SELECTION:(state,data)=>{
            state.education_type_selection = data;
        },
        EXPERIENCE_TYPE_SELECTION:(state,data)=>{
            state.experience_type_selection = data;
        },
        APPLICATION_FORM_JOB_TITLE_SELECTION:(state,data)=>{
            state.application_form_job_title_selection = data;
        },
        SPECIAL_REQUIREMENTS_SELECTION:(state,data)=>{
            state.special_requirements_selection = data;
        },
        SPECIAL_SKILLS_SELECTION:(state,data)=>{
            state.special_skills_selection = data;
        },
        STORE_BRANCHES_SELECTION:(state,data)=>{
            state.store_branches_selection = data;
        },
        SAMPLE_FORM_SELECTION:(state, data)=>{
            state.sample_form_selection = data
        },
        EMPLOYEE_SCHEDULE_TYPE_SELECTION:(state, data)=>{
            state.employee_schedule_type_selection = data
        },
        EMPLOYEE_SCHEDULE_TEMPLATE_SELECTION:(state, data)=>{
            state.employee_schedule_templates = data
        },
        ENTER_BILL_SELECTION:(state,data)=>{
            state.enter_bill_selection = data
        },
        ADMIN_JO_SELECTION:(state,data)=>{
            state.admin_jo_selection = data
        },
        ADMIN_CEF_SELECTION:(state,data)=>{
            state.admin_cef_selection = data
        },
        ACCOUNT_TYPES_SELECTION:(state,data)=>{
            state.account_type_selection = data
        },
        ADM_WAREHOUSE_CODE_DROPDOWN:(state,data)=>{
            state.admin_warehouse_code = data
        },
        CHART_OF_ACCOUNTS_SELECTION:(state,data)=>{
            state.chart_of_accounts_selection = data
        },
        EMOJIS:(state, data)=>{
            state.emojis = data
        },
        ITEM_QA_CHECKED:(state, data)=>{
            state.item_qa_checkeds = data
        },
        BACKJOB_SELECTION:(state, data)=>{
            state.back_job_selection = data
        },
        SP_REPLACEMENT_SELECTION:(state, data)=>{
            state.sp_replacements = data
        },
        SPECIFIC_CHECK_LISTS_SELECTION:(state, data)=>{
            state.get_specific_list = data
        },

        HR_USER_SELECTION:(state, data)=>{
            state.hr_user_selection = data
        },
        FUNDAMENTAL_SELECTION:(state,data)=>{
            state.fundamental_selection = data
        },
        BANK_SELECTION:(state,data)=>{
            state.bank_selection = data
        },
        VENDOR_PAYMENT_ITEM:(state,data)=>{
            state.vendor_payment_item = data
        },
        BOOKLETS_SELECTION:(state,data)=>{
            state.booklets_selection = data
        },
        CHEQUE_VOUCHERS_SELECTION:(state,data)=>{
            state.cheque_vouchers_selection = data
        },
        ACCOUNT_GROUP_SELECTION:(state,data)=>{
            state.account_group_selection = data
        },
        TRANSACT_TYPES_SELECTION:(state,data)=>{
            state.transact_types_selection = data
        },
        COMPANY_SELECTION:(state,data)=>{
            state.company_selection = data
        },
        PV_STORE_BRANCHES_SELECTION:(state,data)=>{
            state.pv_store_branches_selection = data
        },
        EMPLOYEE_DATA_SELECTION:(state,data)=>{
            state.employee_data = data;
        },
        COMPANIES_SELECTION:(state, data)=>{
            state.companies_selection = data
        },
        HOLIDAYS_SELECTION:(state, data) => {
            state.holidays_selection = data
        },
        HOUR_TYPE_SELECTION:(state, data) => {
            state.hour_type_selection = data
        },
        HOLIDAY_TYPE_SELECTION:(state, data) => {
            state.holiday_type_selection = data
        },
        BIO_METRIC_LOCATION_SELECTION:(state, data) => {
            state.bio_metric_location_selection = data
        },
        HR_STATUS_SELECTION:(state, data) => {
            state.hr_status_selection = data
        },
        TIMEKEEPING_PERIOD_SELECTION:(state, data) => {
            state.timekeeping_period_selection = data
        },
        ENTRY_TYPES_SELECTION:(state, data)=>{
            state.entry_types_selection = data
        },
        ACCOUNTING_JOB_SELECTION:(state, data)=>{
            state.accounting_job_selection = data
        },
        ENTER_BILL_SELECTION:(state,data)=>{
            state.enter_bill_selection = data
        },
        ADMIN_JO_SELECTION:(state,data)=>{
            state.admin_jo_selection = data
        },
        ADMIN_CEF_SELECTION:(state,data)=>{
            state.admin_cef_selection = data
        },
        ACCOUNT_TYPES_SELECTION:(state,data)=>{
            state.account_type_selection = data
        },
        // ADM_WAREHOUSE_CODE_DROPDOWN:(state,data)=>{
        //     state.admin_warehouse_code = data
        // },
        CHART_OF_ACCOUNTS_SELECTION:(state,data)=>{
            state.chart_of_accounts_selection = data
        },
        UNASSIGNED_SPARE_PARTS: (state, data) => {
            state.unassigned_spare_parts = data
        },
        CLUSTER_SELECTION: (state, data) => {
            state.clusters.selection = data
        },
        FREQUENCY_SELECTION: (state, data) => {
            // state.frequency_selection = data
            // data.shift()
            state.frequency_selection = data
        },
        PV_COMPANIES_SELECTION: (state, data) => {
            state.pv_companies_selection = data
        },
        SUB_CLUSTER_SELECTION: (state, data) => {
            state.sub_clusters.selection = data
        },
        PV_PRINT_TEMPLATE:(state, data)=>{
            state.pv_print_template = data
        },
        TRANSACTION_TYPE_SELECTION: (state, data) => {
            state.transaction_type_selection = data
        },
        VAT_TYPE_SELECTION: (state, data) => {
            state.vat_type_selection = data
        },
        CONTAINER_SELECTION: (state, data) => {
            state.container_selection = data
        },
        ACTIVE_VENDOR_PAYBILLS: (state, data) => {
            state.active_vendor_paybills = data
        },
        RB_TYPES: (state, data) => {
            state.rb_types = data
        },
        RB_BOOKLETS: (state, data) => {
            state.rb_booklets = data
        },
        SERVICE_PAYMENT_REQUEST_TYPE: (state, data) => {
            state.service_payment_request_type = data
        },
        SHIPPING_VIA: (state, data) => {
            state.shipping_via = data
        },
        MOTHER_BROKER_SELECTION: (state, data) => {
            state.mother_broker_selection = data
        },
        ADMIN_ASSET_CONDITION_SELECTION: (state, data) => {
            state.admin_asset_condition_selection = data
        },
        INBOUND_OUTBOUND_TYPE:(state,data)=>{
            state.inbound_outbound_type = data.filter(e=>e.code != 'FB-IN')
        },
        STORE_BRANCHES_WITHOUT_ACCESS: (state, data) => {
            state.store_branches_without_access = data
        },
        SERVICE_CUSTOMERS_END_USER:(state,data)=>{
            state.service_customers_end_user = data;
        },
        EMPLOYEE_SALARY_TYPE_SELECTION:(state,data)=>{
            state.employee_salary_type_selection = data;
        },
        LOGISTIC_BASKET_SELECTION:(state, data)=>{
            state.logistic_basket_selection = data
        },
        PV_COMPANIES_SELECTION: (state, data) => {
            state.pv_companies_selection = data
        },
        SUB_CLUSTER_SELECTION: (state, data) => {
            state.sub_clusters.selection = data
        },
        PV_PRINT_TEMPLATE:(state, data)=>{
            state.pv_print_template = data
        },
        SERVICE_CUSTOMERS_END_USER:(state,data)=>{
            state.service_customers_end_user = data;
        },
        REPORT_CATEGORY:(state,data)=>{
            state.report_category = data;
        },
        REPORT_DROPDOWN:(state,data)=>{
            state.report_dropdown = data;
        },
        EMPLOYEE_SALARY_TYPE_SELECTION:(state,data)=>{
            state.employee_salary_type_selection = data;
        },
        DISPLAY_LOGISTIC_BASKET_SELECTION:(state, data)=>{
            state.display_logistic_basket_selection = data
        },
        VEHICLE_TYPE_SELECTION:(state,data)=>{
            state.vehicle_type = data;
        },
        STORE_CASH_IN_SELECTION:(state,data)=>{
            state.store_cash_in_selection = data;
        },
        ADMIN_ITEMS_SELECTION:(state,data)=>{
            state.admin_items_selection = data;
        },
        CASH_FLOW_ACTIVITY_SELECTION:(state,data)=>{
            state.cash_flow_activity_selection = data;
        },
        ADMIN_FAS_SELECTION:(state,data)=>{
            state.admin_fas_selection = data;
        },
        DEBIT_MEMO_SELECTION:(state,data)=>{
            state.debit_memo_selection = data;
        },
        CUSTOMER_WITH_DEBIT_MEMO_SELECTION:(state,data)=>{
            state.customer_with_debit_memo_selection = data;
        },
        ADMIN_DELIVERY_ADDRESSES:(state,data)=>{
            state.admin_delivery_addresses = data;
        },
        COMPANY_CLASSIFICATION_SELECTION:(state,data)=>{
            state.company_classification_selection = data;
        },
        SYSTEM_DETAIL_SELECTION:(state,data)=>{
            state.system_detail = data;
        },
        ITEM_PRICE_LIST_BY_VENDOR:(state,data)=>{
            state.item_price_list_by_vendor = data;
        },
        ADMIN_BASKET_SELECTION:(state,data)=>{
            state.admin_basket_selection = data
        },
        PICKER_PERSONNELS:(state, data) => {
            state.picker_personnels = data;
        },
        CARD_NETWORK_SELECTION:(state, data) => {
            state.card_network_selection = data;
        },
        BANK_CARD_SELECTION:(state, data) => {
            state.bank_card_selection = data;
        },
        DIMENSION_TYPE_SELECTION:(state, data) => {
            state.dimension_type_selection = data;
        },
        REQUISITION_SELECTION:(state, data) => {
            state.requisition_selection = data;
        },
        PO_WITH_ASSET_LIST:(state, data) => {
            state.po_with_asset_list = data;
        },
        EMPLOYEE_FOR_TURN_OVER:(state, data) => {
            state.employee_turn_over = data;
        },
        BANK_MASTERS_LIST:(state, data) => {
            state.bank_masters_list = data;
        },
        VENDORS_WITH_PENDING_CARD_PAYMENTS:(state, data) => {
            state.vendors_with_pending_card_payments = data;
        },
        JOURNAL_VOUCHER_TYPE_SELECTION:(state, data) => {
            state.journal_voucher_type_selection = data;
        },
        CONTAINER_TYPE_SELECTION:(state, data) => {
            state.container_type_selection = data;
        },
        PACKING_LIST_RELOAD:(state, data) => {
            state.pl_reload = data;
        },
        FUND_TRANSFER_TYPE_SELECTION: (state, data) => {
            state.fund_transfer_type_selection = data
        },
    },
};
