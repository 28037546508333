import repository from "@/api/modules/Reports/SalesReports/index";
import store from "@/store";


export default {
    state:{
            reports:{
            Sales:[
                {
                    title:'Sales By Customers',
                    route:'sales-by-customers'
                },
                {
                    title:'Sales By Customer Detailed',
                    route:'sales-by-customer-detailed'
                },
                {
                    title:'Sales By Customer Packing Lists',
                    route:'sales-by-customer-packing-lists'
                },
                {
                    title:'Sales By Items',
                    route:'sales-by-items'
                },
                {
                    title:'Sales By Classifications',
                    route:'sales-by-classifications'
                },
                {// not yet finish
                    title:'Sales By Region',//
                    route:'sales-by-region'//
                },
                {
                    title:'Top Performing Concept Store',
                    route:'top-performing-concept-store'
                },
                {
                    title:'Top/Poor Selling Items',
                    route:'top-poor-selling'
                },
                {
                    title:'Pending Stock Card By PL',
                    route:'pending-stock-Card-by-pL'
                },
                {
                    title:'Customer Pending Orders By SO',
                    route:'customer-pending-orders-by-so'
                },
                {
                    title:'Customer Pending Orders By Items',
                    route:'customer-pending-orders-by-items'
                },
                {
                    title:'Pending Orders By Items',
                    route:'pending-orders-by-items'
                },
                {
                    title:'Customer Sold unsold',
                    route:'customer-sold-unsold'
                },
                {
                    title:'New Items',
                    route:'new-item'
                },
                {
                    title:'SO Converted To WPF',
                    route:'so-converted-to-wpf'
                },
                {
                    title:'Sales Order Per Items',
                    route:'sales-order-per-items'
                },
                {
                    title:'Booked vs Invoiced Orders',
                    route:'booked-vs-invoiced-items'
                },
                {
                  title:'Item Sales Details',
                  route:'item-sales-details'
                },
                {
                  title:'Item Sales Details Count',
                  route:'item-sales-details-count'
                },
                {
                    title:'Pending Order Stock Analysis',
                    route:'pending-order-stock-analysis'
                },
                {
                    title:'Sales By Customer Detailed (Comparison)',
                    route:'sales-by-customer-detailed-comparison'
                  },
                {
                    title:'Sales By Clusters',
                    route:'sales-by-clusters'
                }
            ],
            Inventory:[
              {
                  title:'Inventory Counts',
                  route:'inventory-counts'
              },
              {
                  title:'Item Movements',
                  route:'item-movements'
              },
              {
                  title:'Daily Movement Reports',
                  route:'daily-movement-reports'
              },
              {
                  title:'Pending Stockcard By Customers',
                  route:'pending-stockcard-by-customers'
              },
              {
                  title:'Pending Stockcards By Items',
                  route:'pending-stockcard-by-items'
              },
              {
                  title:'Pending Stock Card By PL',//
                  route:'pending-stockcard-by-pl'//
              },
              {
                  title:'Cycle Count Status Percentage',
                  route:'cycle-count-status-percentage'
              },
              {
                  title:'Cycle Count Consolidated Item Variance',
                  route:'cycle-count-consolidated-variance'
              },
              {
                  title:'Stock Request Reports',
                  route:'stock-request-reports'
              },
              {
                  title:'Stock Transfer Reports',
                  route:'stock-transfer-reports'
              },
              {
                  title:'Daily Warehouse Inventory Reports',
                  route:'daily-warehouse-inventory-reports'
              },
              {
                  title:'Trucks Per Day Reports',
                  route:'trucks-per-day-reports'
              },
              {
                  title:'Shipment Per Item Reports',
                  route:'shipment-per-item-reports'
              },
              {
                  title:'Sales Quotation Reports',
                  route:'sales-quotations-reports'
              },
              {
                  title:'Container Per Item Reports',
                  route:'daily-warehouse-inventory-reports'
              },
              {
                  title:'Trucks Per Day Reports',
                  route:'trucks-per-day-reports'
              },
              {
                  title:'Order Items With Stock Reports',
                  route:'order-items-with-stock-reports'
              },
              {
                  title:'Items With New Models Report',
                  route:'items-with-new-models-report'
              },
              {
                  title:'Inactive Items: Actual vs Virtual Stocks',
                  route:'inactive-items-reports'
              },
              {
                  title:'Items Which Container Last Arrived ',
                  route:'items-which-container-last-arrived'
              },
              {
                  title:'Items Based on Stock Range',
                  route:'items-based-on-stock-range'
              },
              {
                  title:'Warehouse Stocks vs Item Movement',
                  route:'warehouse-stocks-vs-item-movement'
              },
          ],
          Accounting:[
            {
                title:'Credit Memo Detailed',
                route:'credit-memo-detailed-component'
            },
            {
                title:'Debit Memo Detailed',
                route:'debit-memo-detailed-component'
            },
            {
                title:'SOA Payment Status Reports',
                route:'soa-payment-status-report'
            },
            {
                title:'Net A/R Reports',
                route:'net-ar-report'
            },
            {
                title:'Collection Report',
                route:'cheque-report'
            },
            {
                title:'Cheque Deposit Report',
                route:'cheque-deposit-report'
            },
            {
                title:'Bank Transaction Report',
                route:'bank-transaction-report'
            },
            {
                title:'Bounced Cheque Report',
                route:'bounced-cheque-report'
            },
            {
                title:'Bank Recon Report',
                route:'bank-recon-report'
            },
            {
                title:'Sales Incentives Report',
                route:'sales-incentives-report'
            },
            {
                title:'PL vs SOA Report',
                route:'pl-soa-report'
            },
            {
                title:'Store Cash In Report',
                route:'store-cash-in-report'
            },
        ],
        Admin: [{
            icon:'mdi-file-document',
            title:'ASSET MANAGEMENT',
            route:'assetmanagementreport'
        },
        {
            icon:'mdi-file-document',
            title:'FAS REPORTS',
            route:'sales-by-customers'
        }],
        Logistic: [
            {
                title:'LOGISTICS REPORT',
                route:'logistics-report'
            }
        ]
        },

          filters:{},
          data:{
              data:[],
              headers:[]
          },
          expantion:{
              data:[]
          },
          chart:null

    },

    getters:{
        SALES_REPORTS_SELECTION:(state)=>state.reports.salesReports,
        GET_FILTER_VALUE:(state)=>state.filters,
        GET_SALES_REPORT_DATA:(state)=>state.data,
        GET_SALES_REPORT_EXPANTION_DETAILS:(state)=>state.expantion.data,
        GET_SALES_REPORT_CHART:(state,data)=>{(state.chart = data)},
        GET_SALES_REPORTS:(state)=>state.reports
    },

    actions:{
        reports({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.reports(payload).then((response)=>{
                    commit('SALES_REPORT_DATA',response.data)
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },

        salesReportsPost({commit},payload){
          return new Promise((resolve,reject)=>{
              repository.salesreportspost(payload).then((response)=>{
                  commit('SALES_REPORT_DATA',response.data)
                  resolve(response)
              }).catch(e=>{
                  reject(e)
              })
          })
      },

    async salesReportExpansion({commit},payload){
        return await new Promise((resolve,reject)=>{
              repository.reportexpansion(payload).then((response)=>{
                  commit('SALES_REPORT_EXPANTION_DETAILS',response.data)
                  resolve(response)
              }).catch(e=>{
                  reject(e)
              })
          })
      },

        salesReportsCharts({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.salesreportscharts(payload).then((response)=>{
                    commit('SALES_REPORT_CHART',response.data)
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },
        reportsPost({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.reportspost(payload).then((response)=>{
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },
    },

    mutations:{
        SALES_REPORTS_SELECTION:(state)=>{(state.reports.salesReports)},
        FILTER_VALUE:(state,data)=>{(state.filters = data)},
        SALES_REPORT_DATA:(state,data)=>{(state.data = data)},
        SALES_REPORT_EXPANTION_DETAILS:(state,data)=>{(state.expantion.data = data)},
        SALES_REPORT_CHART:(state,data)=>{(state.chart = data)},
    }
}
