import repository from "@/api/modules/Accounting/Currency/index";

export default {
  state: {
    currencies: {
      master: {
        list: [],
        dropdown: [],
      },
      purchases: {
        list: [],
        dropdown: [],
      },
    },
  },
  getters: {
    GET_CURRENCIES_MASTER_LIST: (state) => state.currencies.master.list,
    GET_CURRENCIES_MASTER_DROPDOWN: (state) => state.currencies.master.dropdown,

    GET_CURRENCIES_PURCHASES_LIST: (state) => state.currencies.purchases.list,
    GET_CURRENCIES_PURCHASES_DROPDOWN: (state) => state.currencies.purchases.dropdown,
  },
  mutations: {
    COMMIT_CURRENCIES_MASTER_DROPDOWN: (state, payload) => state.currencies.master.dropdown = payload,
  },
  actions: {
    processCurrencies({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository[`${payload.method}Currencies`](payload)
          .then((response) => {
            if (response.status == 200) {
              if (payload.url == 'master/dropdown') {
                commit('COMMIT_CURRENCIES_MASTER_DROPDOWN', response.data)
              }

              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
