import api from '../../../api'

export default {
    getClaims(params) {
        return api.get(`accounting/claims/${params.url}`, { params })
    },
    postClaims(params) {
        return api.post(`accounting/claims/${params.url}`, params)
    },
    putClaims(params) {
        return api.put(`accounting/claims/${params.url}`, params)
    },
    deleteClaims(params) {
        return api.delete(`accounting/claims/${params.url}`, params)
    },
}
