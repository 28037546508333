
import api from "../../api";
export default {
    userselectlist() {
        return api.get("dropdown/users-list");
    },
    salesmanpositions() {
        return api.get("dropdown/salesmanpositions-list");
    },
    salesmanhead() {
        return api.get("dropdown/salesman-head-list");
    },
    salesman() {
        return api.get("dropdown/salesman");
    },
    salesmancoor() {
        return api.get("dropdown/salesman-coordinator");
    },
    countries() {
        return api.get("dropdown/countries");
    },
    regions() {
        return api.get("dropdown/regions");
    },
    province() {
        return api.get("dropdown/province");
    },
    cities() {
        return api.get("dropdown/cities");
    },
    vendortype() {
        return api.get("dropdown/vendor-type");
    },
    itemselectiondefault() {
        return api.get("/dropdown/items", { params: { types: [1, 2] } });
    },
    affiliatecustomer() {
        return api.get("dropdown/affiliate-customer");
    },
    branddiscount() {
        return api.get("dropdown/brand-discount");
    },
    getallcustomerclassificationselection() {
        return api.get("dropdown/classifications");
    },
    getallcustomerCategory() {
        return api.get("dropdown/customer-category");
    },
    itemselection(params) {
        return api.get("/dropdown/items", {params});
    },
    customersSelection(params) {
        return api.get("/dropdown/customers", {params});
    },
    salesmanSelection(params) {
        return api.get("/dropdown/salesman", params);
    },
    vehicles(params) {
        return api.get("/dropdown/vehicles-list", {params});
    },
    promos() {
        return api.get("/dropdown/getPromos");
    },
    accountingusers() {
        return api.get("/dropdown/getAccountingUsers");
    },
    department_role(){
        return api.get('/dropdown/department-list-role');
    },
    getactualwarehousecodes(){
        return api.get('/dropdown/getActualWarehouseCodes');
    },
    getSalesByRegionReports(){
        return api.get('/dropdown/getRegions');
    },
    getBackJobDropDown(){
        return api.get(`/dropdown/getBackJobDropDown`)
    },
    getVendorsSelection(){
        return api.get('/dropdown/getVendorsSelection');
    },
    dropdownGet(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    creditMemoClassification() {
        return api.get(`/dropdown/creditMemoClassificationDropdown`)
    },
    getAllCreditMemoSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAllCustomerWithCMSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    itemselectionExcludeSp(){
        return api.get('/dropdown/item-selection-exclude-sp')
    },
    getAllPackingListNumSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAllCustomerWithPLSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAdminSupplierSelection(params){
        return api.get('/dropdown/getAdminSupplier', {params});
    },
    getContactPersonSelection(param){
        return api.get(`/dropdown/getContactPersonSelection/${param}`);
    },
    getTermSelection(param){
        return api.get(`/dropdown/getTermSelection/${param}`);
    },
    getAdminClassificationSelection(){
        return api.get('/dropdown/getAdminClassification');
    },
    getAdminSubClassificationSelection(){
        return api.get('/dropdown/getAdminSubClassification');
    },
    getAdminLocationSelection(params){
        return api.get('/dropdown/getAdminLocation', {params});
    },
    getAdminBrandSelection(){
        return api.get('/dropdown/getAdminBrand');
    },
    getStatus(params){
        return api.get('/dropdown/getStatus', {params});
    },
    getEmployeeDetails(){
        return api.get('/dropdown/EmployeeDetails');
    },
    getAdminCategories(params){
        return api.get('/dropdown/getAdminCategories', {params});
    },
    getEmployeeReligionSelection(){
        return api.get('/dropdown/getReligion');
    },
    getEmployeeMaritalStatusSelection(){
        return api.get('/dropdown/getMaritalStatus');
    },
    getEmployeeCitizenshipSelection(){
        return api.get('/dropdown/getCitizenship');
    },
    getEmployeeJobTitleSelection(){
        return api.get('/dropdown/getJobTitles');
    },
    getEmployeeContractTypeSelection(){
        return api.get('/dropdown/getContractTypes');
    },
    getEmployeeSuperiorSelection(){
        return api.get('/dropdown/getEmployeeSuperior');
    },
    getitemUomSelection(){
        return api.get('/dropdown/item-uom');
    },
    getAdminItemUomSelection(){
        return api.get('/dropdown/admin-item-uom');
    },
    getPRFSelection(){
        return api.get('/dropdown/getPrfNumber');
    },
    getAdminPoSelection(params){
        return api.get('/dropdown/adminPurchaseOrderSelection',{params});
    },
    getJobLevelSelection(params){
        return api.get('/dropdown/getJobLevel',{params});
    },
    getLeaveTypeSelection(params){
        return api.get('/dropdown/getLeaveType',{params});
    },
    getApplicationStatusSelection(params){
        return api.get('/dropdown/getApplicationStatus',{params});
    },
    getPONumberAssetsSelectionn(params){
        return api.get('/dropdown/PONumberAssetsSelection',{params});
    },
    creditMemoClassification() {
        return api.get(`/dropdown/creditMemoClassificationDropdown`)
    },
    getAllCustomerWithCMSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    itemselectionExcludeSp(){
        return api.get('/dropdown/item-selection-exclude-sp')
    },
    getAllPackingListNumSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getEmployeeDataDropdown(params){
        return api.get('/dropdown/getEmployeeDataDropdown',{params});
    },
    getCompanySelection(params){
        return api.get('/dropdown/getCompanySelection',{params});
    },
    getEmployeeSalaryTypeSelection(params){
        return api.get('/dropdown/getEmployeeSalaryTypeSelection',{params});
    },
    getServiceCustomers(params){
        return api.get('/dropdown/service-customers',{params});
    },
    getAllCustomerWithPLSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    repairCategorySelection(){
        return api.get(`/dropdown/repairCategorySelection`)
    },
    repairTypeSelection(){
        return api.get(`/dropdown/repairTypeSelection`)
    },
    getExclusivityTypeSelection(){
        return api.get('/dropdown/exclusivityTypeDropdown')
    },
    getallcustomercodeselection() {
        return api.get("dropdown/customer_codes");
    },
    getWarehousesRequest() {
        return api.get("dropdown/stock/request/warehouse");
    },
    getLists(params){
        return api.get('dropdown/check-list', {params});
    },
    getTemplate(params){
        return api.get("dropdown/list-template/{id}", {params});
    },
    getCheckListTemplate(params){
        return api.get("dropdown/check-list-template", {params});
    },
    getClientListSelection(params){
        return api.get('/dropdown/getClientList',{params});
    },
    getManpowerRequestReasonTypeSelection(params){
        return api.get('/dropdown/getManpowerRequestReasonType',{params});
    },
    getEducationTypeSelection(params){
        return api.get('/dropdown/getEducationType',{params});
    },
    getExperienceTypeSelection(params){
        return api.get('/dropdown/getExperienceType',{params});
    },
    getApplicationFormJobTitlesSelection(params){
        return api.get('/dropdown/getApplicationFormJobTitles',{params});
    },
    getSpecialRequirementsSelection(params){
        return api.get('/dropdown/getSpecialRequirements',{params});
    },
    getSpecialSkillsSelection(params){
        return api.get('/dropdown/getSpecialSkills',{params});
    },
    getStoreBranchesSelection(params){
        return api.get('/dropdown/getStoreBranches',{params});
    },
    getSampleFormSelection(params) {
        return api.get("dropdown/getSampleFormSelection",{params});
    },
    getEmployeeScheduleType(params) {
        return api.get("dropdown/getEmployeeScheduleType",{params});
    },
    getEmployeeScheduleTemplates(params) {
        return api.get("dropdown/getEmployeeScheduleTemplates",{params});
    },
    getItemChecked(params){
        return api.get('dropdown/item-checked', {params});
    },
    getEmoji(params){
        return api.get('dropdown/getEmoji',{params});
    },
    getBackJobSelection(params){
        return api.get('dropdown/getBackJobSelection',{params});
    },
    getSpReplacementsSelection(params){
        return api.get('dropdown/getSpReplacementsSelection',{params});
    },
    getSpecificListSelection(params){
        return api.get('dropdown/getSpecificListSelection',{params});
    },
    getCompaniesDropdown(params) {
        return api.get("dropdown/getCompaniesDropdown",{params});
    },
    hrusers() {
        return api.get("/dropdown/getHrUsers");
    },
    getHolidays(params) {
        return api.get("/dropdown/getHolidays", {params});
    },
    getHourTypes() {
        return api.get("/dropdown/getHourTypes");
    },
    getHolidayTypes() {
        return api.get("/dropdown/getHolidayTypes");
    },
    getBioMetricLocationSelection() {
        return api.get("/dropdown/getBioMetricLocationSelection");
    },
    getStatusHR(params){
        return api.get('/dropdown/getStatusHR', {params});
    },
    getTimekeepingPeriods(params){
        return api.get('/dropdown/getTimekeepingPeriods', {params});
    },
    getCOASelection(params){
        return api.get('dropdown/getCOASelection',{params});
    },
    getAccountTypes(){
        return api.get("dropdown/getAccountTypes");
    },
    getFundamentals(){
        return api.get("dropdown/getFundamentals");
    },
    getAccountGroup(){
        return api.get("dropdown/getAccountGroup");
    },
    getTransactTypesSelection(params){
        return api.get("dropdown/getTransactTypesSelection",{params});
    },
    getPaymentVoucherBranchesSelection(params){
        return api.get("dropdown/getPaymentVoucherBranchesSelection",{params});
    },
    getCompaniesDropdown(params) {
        return api.get("dropdown/getCompaniesDropdown",{params});
    },
    getEntryTypesSelection(params) {
        return api.get("dropdown/getEntryTypesSelection",{params});
    },
    getAccountingJobSelection(params) {
        return api.get("dropdown/getAccountingJobSelection",{params});
    },
    getadminPurchaseOrderNum(params) {
        return api.get("dropdown/getadminPurchaseOrderNum",{params});
    },
    getAdminWarehouseCodeDropdown(params) {
        return api.get("dropdown/getAdminWarehouseCodeDropdown",{params});
    },
    getAdminStockfromPO(params) {
        return api.get(`dropdown/getAdminStockfromPO/${params}`);
    },
    getSelectedAdminStockfromPO(params) {
        return api.get(`dropdown/getSelectedAdminStockfromPO/${params}`);
    },
    getadminPurchaseOrderNumSelection(params) {
        return api.get(`dropdown/getadminPurchaseOrderNumSelection/${params.id}`);
    },
    getfindPRFfromPO(params) {
        return api.get(`dropdown/findPRFfromPO/${params.id}`);
    },
    getunassignedspareparts(params){
        return api.get('dropdown/unassigned-spare-parts', {params});
    },
    getClusterSelection(params) {
        return api.get('dropdown/getClusterSelection', { params })
    },
    getFrequencySelection(params) {
        return api.get('dropdown/getFrequencySelection', { params })
    },
    getBankSelection(params){
        return api.get("dropdown/getBanks",{ params });
    },
    getVehicleType(params){
        return api.get('dropdown/getVehicleType',{params});
    },
    getVendorPaymentItems(params){
        return api.get(`dropdown/getVendorPaymentItems/${params}`);
    },
    getBookletsSelection(params){
        return api.get(`dropdown/getBookletsSelection/${params}`);
    },
    getChequeVouchersSelection(params){
        return api.get(`dropdown/getChequeVouchersSelection`, {params});
    },
    getPVPrintTemplate(params) {
        return api.get("dropdown/getPVPrintTemplate",{params});
    },
    getunassignedspareparts(params){
        return api.get('dropdown/unassigned-spare-parts', {params});
    },
    getPvCompanies(params){
        return api.get('dropdown/getPvCompanies', { params })
    },
    getTransactionTypeSelection(params){
        return api.get('dropdown/getTransactionTypeSelection', { params })
    },
    getSubClusterSelection(params) {
        return api.get('dropdown/getSubClusterSelection', { params })
    },
    getReportCategory(params) {
        return api.get('dropdown/getReportCategory', { params })
    },
    getReportsDropdown(params) {
        return api.get('dropdown/getReportsDropdown', { params })
    },
    getVatTypeSelection(params) {
        return api.get('dropdown/getVatTypeSelection', { params })
    },
    getContainer(params) {
        return api.get('dropdown/getContainer', { params })
    },
    getActiveVendorPaybills(params) {
        return api.get('dropdown/getActiveVendorPaybills', { params })
    },
    getRecurringBillTypes(params) {
        return api.get('dropdown/getRecurringBillTypes', { params })
    },
    getRecurringBillBooklets(params) {
        return api.get('dropdown/getRecurringBillBooklets', { params })
    },
    getServicePaymentRequestType(params) {
        return api.get('dropdown/getServicePaymentRequestType', { params })
    },
    getShippingVia(params) {
        return api.get('dropdown/getShippingVia', { params })
    },
    getMotherBrokerSelection(params) {
        return api.get('dropdown/getMotherBrokerSelection', { params })
    },
    getAdminAssetConditionSelection(params) {
        return api.get('dropdown/getAdminAssetConditionSelection', { params })
    },
    getPOReceiving(params) {
        return api.get('dropdown/getPOReceiving', { params })
    },
    getInboundOutBoundType(params) {
        return api.get('dropdown/getInboundOutBoundType', { params })
    },
    getStoreBranchesWithoutAccess(params) {
        return api.get('dropdown/getStoreBranchesWithoutAccess', { params })
    },
    dropdownsApiGet(params){
        return api.get(`${params.prefix}/${params.url}`, {params});
    },
    getLogisticBasket(params){
        return api.get('dropdown/getLogisticBasket',{params});
    },
    getDisplayLogisticBasket(params){
        return api.get('dropdown/getDisplayLogisticBasket',{params});
    },
    getAdminItemsSelection(params){
        return api.get('dropdown/get-admin-items-selection',{params});
    },
    getCashFlowActivities(params){
        return api.get('dropdown/getCashFlowActivities',{params});
    },
    getFasRequesition(params){
        return api.get('dropdown/getFasRequesition',{params});
    },
    getAllDebitMemoSelection(params) {
        return api.get('/dropdown/'+params.url,{params});
    },
    getAllCustomerWithDMSelection(params){
        return api.get('/dropdown/'+params.url,{params})
    },
    getAllLocationWarehouseSelection(params) {
        return api.get('/dropdown/'+params.url,{params})
    },
    getAdminDeliveryAddressSelection(params){
        return api.get('dropdown/get-admin-delivery-address-selection',{params});
    },
    getCompanyClassificationDropdown(params) {
        return api.get("dropdown/getCompanyClassificationDropdown",{params});
    },
    systemDetailDropdown(params) {
        return api.get("dropdown/systemDetailDropdown",{params});
    },
    getItemPriceListByVendor(params) {
        return api.get("dropdown/getItemPriceListByVendor",{params});
    },
    getAdminBasketSelection(params) {
        return api.get("dropdown/getAdminBasketSelection",{params});
    },
    getpickerpersonnels(params) {
        return api.get("dropdown/picker-personnels", {params});
    },
    getCardNetworks(params) {
        return api.get("dropdown/getCardNetworks",{params});
    },
    getBankCards(params) {
        return api.get("dropdown/getBankCards",{params});
    },
    getDimensionTypeSelection(params) {
        return api.get("dropdown/get-dimension-type-selection",{params});
    },
    getAdminRequisitionSelection(params) {
        return api.get("dropdown/getAdminRequisitionSelection",{params});
    },
    getPoWithAssetList(params) {
        return api.get("dropdown/get-po-with-asset-list",{params});
    },
    getEmployeeForTurnOver(params){
        return api.get('/dropdown/getEmployeeForTurnOver',{params});
    },
    getBankMastersList(params) {
        return api.get("dropdown/get-bank-masters-list",{params});
    },
    getVendorsWithPendingCardPayments(params) {
        return api.get("dropdown/get-vendors-with-pending-card-payments",{params});
    },
    getJournalVoucherTypes(params){
        return api.get('/dropdown/getJournalVoucherTypes',{params});
    },
    getContainerTypes(params) {
        return api.get('dropdown/getContainerTypes', { params })
    },
    getFundTransferTypeSelection(params){
        return api.get('/dropdown/getFundTransferTypeSelection',{params});
    },
};