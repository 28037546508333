import Vue from 'vue'
import Vuetify, {
  VApp, VMain,
  VContainer, VRow, VCol, VLayout, VFlex,
  VForm,
  VBtn, VIcon, VSwitch, VRadioGroup, VRadio,
  VCard, VCardTitle, VCardText, VCardActions,
  VTextField, VTextarea,
  VAutocomplete, VSelect, VCombobox,
  VCheckbox, VSimpleCheckbox,
  VDataTable, VSimpleTable,
  VProgressLinear, VProgressCircular,
  VTooltip, VToolbar, VToolbarTitle,
  VAppBar, VAppBarTitle, VAppBarNavIcon, VNavigationDrawer,
  VListGroup, VList, VListItem, VListItemAvatar,
  VTreeview,
  VTabs, VTabsItems, VTab, VTabItem, VTabsSlider,
  VSpacer, VDivider,
  VBadge, VChip, VAvatar,
  VDialog, VMenu,
  VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent,
  VDatePicker, VTimePicker,
  VCalendar,
  VSheet, VWindow, VWindowItem,
  VImg,
  VOverlay,
  VSlider,
  VOtpInput,
  VFileInput,
  VHover,
  VSnackbar,
  VLazy,
  VSkeletonLoader,
  VBreadcrumbs,
  VBreadcrumbsItem,
} from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  components: {
    VApp, VMain,
    VContainer, VRow, VCol, VLayout, VFlex,
    VForm,
    VBtn, VIcon, VSwitch, VRadioGroup, VRadio,
    VCard, VCardTitle, VCardText, VCardActions,
    VTextField, VTextarea,
    VAutocomplete, VSelect, VCombobox,
    VCheckbox, VSimpleCheckbox,
    VDataTable, VSimpleTable,
    VProgressLinear, VProgressCircular,
    VTooltip, VToolbar, VToolbarTitle,
    VAppBar, VAppBarTitle, VAppBarNavIcon, VNavigationDrawer,
    VListGroup, VList, VListItem, VListItemAvatar,
    VTreeview,
    VTabs, VTabsItems, VTab, VTabItem, VTabsSlider,
    VSpacer, VDivider,
    VBadge, VChip, VAvatar,
    VDialog, VMenu,
    VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent,
    VDatePicker, VTimePicker,
    VCalendar,
    VSheet, VWindow, VWindowItem,
    VImg,
    VOverlay,
    VSlider,
    VOtpInput,
    VFileInput,
    VHover,
    VSnackbar,
    VLazy,
    VSkeletonLoader,
    VBreadcrumbs,
    VBreadcrumbsItem,
  },
}

export default new Vuetify(opts)
