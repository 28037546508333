import repository from '@/api/modules/Accounting/FundTransfer/fundtransfer'
export default {
    state:{
        ACTION: '',
        TAB: ''
    },
    getters:{
        FUND_TRANSFER: (state) => state
    },
    actions:{
        fundTransferGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.fundTransferGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        fundTransferPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.fundTransferPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
    mutations:{
        FUND_TRANSFER_ACTION: (state, data) => { (state.ACTION = data) },
        FUND_TRANSFER_TAB:(state, data)=>{ (state.TAB = data) }
    }
}
