import repository from '@/api/modules/Accounting/Claims/index';

export default {
    state: {
        claims: {
            platforms: {
                list: [],
                dropdown: [],
            },
            shops: {
                list: [],
                dropdown: [],
            },
            templates: {
                list: [],
                dropdown: [],
            },
        },
    },
    getters: {
        GET_CLAIMS_PLATFORMS_LIST: (state) => state.claims.platforms.list,
        GET_CLAIMS_SHOPS_LIST: (state) => state.claims.shops.list,
        GET_CLAIMS_TEMPLATES_LIST: (state) => state.claims.templates.list,
        GET_CLAIMS_PLATFORMS_DROPDOWN: (state) => state.claims.platforms.dropdown,
        GET_CLAIMS_SHOPS_DROPDOWN: (state) => state.claims.shops.dropdown,
        GET_CLAIMS_TEMPLATES_DROPDOWN: (state) => state.claims.templates.dropdown,
    },
    mutations: {

    },
    actions: {
        processClaims({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository[`${payload.method}Claims`](payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
}
